
  .hotel-detail {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-header;
    @include create-card-content;
    @include create-row-card-footer;
    @include create-hotel-row;
    &_card {
      box-shadow: $row-shadow-color;
    }
    &__content {
      @include make-four-fifth;
      margin-top:0px;
      min-height: calc(#{$base-padding}/3 + 80px);
    }
    &__title {
      text-transform: uppercase;
      color: $brand-color-2;
      padding: 0.9375rem 0 0.375rem 0.375rem;
      padding-left:0px;
      width: 50%;
    }
    &__date{
    width: 50%;
    float: right;
    }
    &__header {
      width: 100%;
      background-color: $brand-color-4;
      padding: $base-padding/6;
      text-transform: uppercase;
      @include clearfix;
      .header-empty {
        @include make-one-fifth;
      }
      .header-text {
        @include make-four-fifth;
        @include clearfix;
        padding-left: 0;
        .header_text {
          @include make-three-fifth;
          padding-left: 3px;
          text-align: left;
          &:last-child {
            @include make-two-fifth;
            padding-left: 1.7rem;
          }
        }
      }
    }
  }
  .content {
    &__image {
      @include clearfix;
      @include make-one-fifth;
      padding-top: $base-padding/3;
      padding-bottom: $base-padding/3;
      > img {
         width: 100%;
 		 max-height: 80px;
      }
    }
    &__description,
    &__extra-desc {
      @include clearfix;
      padding-top: $base-padding/3;
      width: 100%;
    }
    &__description {
      border-bottom: 2px solid $brand-color-4;
      padding-bottom: $base-padding/3;
    }
  }
  .description {
    &__hotel {
      @include make-three-fifth;
      padding-left: 0;
      &_name,
      &_nights {
        max-height: 100px;
        text-align: left;
        font-family: $font-family-number;
        color: $brand-color-2;
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
      }
      &_nights {
        color: $brand-color-5;
        font-size: 12px;
        font-weight: 600;
      }
    }
    &__rating {
      @include make-two-fifth;
      padding-right: 0;
      max-height: 100px;
      .glyphicon {
        color: $star-rating;
      }
    }
  }
  .extra-desc {
    &__country {
      @include make-three-fifth;
      padding-left: 0;
      text-transform: capitalize;
      &_name {
        color: $brand-color-5;
        max-height: 100px;
        text-align: left;
        font-family: $font-family-number;
        font-size: 12px;
        font-weight: 600;
      }
    }
    &__room {
      @include make-two-fifth;
      padding-right: 0;
      &-type {
        color: $brand-color-5;
        text-transform: capitalize;
      }
    }
  }

