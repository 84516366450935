.package__search_result {
  @include search-info;
  .search-details {
    @include create-main-wrapper;
    background-color: $md-bgcolor;
    &_wrapper {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &__row {
      @include clearfix;

      // margin-left: 15px;
      > li {
        @include make-one-fourth;
        padding-left: 0;
        padding-right: 0;
        border-right: 2px solid $md-border-color;
        &:first-child {
          border-left: 2px solid $md-border-color;
        }
        &:nth-child(3) {
          border-right: none;
        }
        &:last-child {
          padding-right: $base-padding/2;
          border-right: none;
        }
      }
      &_search-parameters {
        margin-right: -15px;
      }
    }
    .from-date,
    .to-date,
    .destination {
      &__data,
       {
        @include clearfix;

        // border-left: 2px solid white;
        padding-left: $base-padding/2;
        padding-right: $base-padding/2;
        &_icon {
          float: left;
          font-size: 2.25rem;
          width: 20%;
        }
        &_detail {
          float: left;
          padding: 9px;
          width: 80%;
          .location {
            color: $md-heading-color;
          }
          .departure {
            @include clearfix;
            .departure__heading {
              float: left;
            }
            .departure__date {
              float: left;
            }
          }
        }
      }
    }
    .search-parameters {
      &__row {
        @include clearfix;
        > li {
          text-align: center;
          width: 25%;
          border-left: 2px solid $md-border-color;
          padding-top: 9px;
          padding-bottom: 9px;
          float: left;
          &:last-child {
            border-right: 2px solid $md-border-color;
          }
        }
      }
    }
  }
  .destination__data,
  .from-date__data,
  .to-date__data,
   {
    &_icon {
      span.glyphicon.glyphicon-arrow-right,
      span.glyphicon.glyphicon-arrow-left,
      span.glyphicon.glyphicon-map-marker {

        //  transform: rotate(45deg);
        top: 5px;
      }
    }
  }
}
.passenger-info-modal {
  @include create-modify-search-form;
}
