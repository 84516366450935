@mixin create-package-detail-modal-container_private {
  .package-rate-details {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: $base-padding/3;
    padding-right: $base-padding/3;
    padding-bottom: $base-padding/3;
    &__content {
      background-color: $brand-color-4;
      border-left: 10px solid $brand-color-3;
      border-right: 10px solid $brand-color-3;
      border-top: 10px solid $brand-color-3;
      border-bottom: 10px solid $brand-color-3;
    }
  }
  .package-table {
    background-color: $light;
    border-collapse: collapse;
    overflow: auto;
    width: 100%;
    table-layout: fixed;
    tr {
      padding: $base-padding/6;
      border-bottom: 1px solid $brand-color-4;
    }
    tr:last-child {
      border-bottom: 0;
    }
    th {
      border-bottom: 0;
      text-transform: uppercase;
      background-color: $brand-color-4;
    }
    td,
    th {
      padding-left: $base-padding/6;
      padding-top: $base-padding/6;
      padding-bottom: $base-padding/6;
      text-align: left;
    }
    td > .result__book_button {
      width: 70%;
      margin-left: 20%;
    }
    td > a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      color: $brand-color-2;
      font-size: 11px;
    }
    th > button {
      background: transparent;
      border: none;
      padding-right: 1rem;
      float: right;
    }
    td:nth-child(4),
    th:nth-child(4) {
      text-align: right;

      // padding-left: 9rem;
    }
    td:nth-child(4) {
      padding-left: 3rem;
    }
    &__package-rate {
      color: $dark;
    }
    &__currency {
      float: left;
    }
  }
  .package-details-modal {
    .modal__content {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    .modal-dialog {
      width: 80%;
      margin: 30px auto;
    }
    .modal-header {
      padding-bottom: $base-padding/6;
      padding-right: 0;
      padding-left: 0;
      border-bottom: 0;
      @include clearfix;
    }
    .modal-title {
      color: $brand-color-2;
      padding-bottom: $base-padding/5;
      >button {
        float: right;
        background-color: $light;
      }
    }
  }
  .package-details,
  .package-hotel-highlights,
  .dynamic-package-details {
    @include clearfix;
    height: 500px;
    .highlights {
      &__sidebar {
        float: left;
        width: 25%;
        height: 100%;
        background-color: $body-bg;
      }
      &__hotel-details {
        float: right;
        width: 75%;
        height: 100%;
        margin-top: 0;
      }
    }
    .sidebar {
      &__back-button {
        @include clearfix;
        .triangle__container {
          border-radius: 100%;
          background-color: $brand-color-1;
          position: relative;
          float: right;

          //overflow:auto;
          //bottom: 7px;
          width: 25px;
          height: 25px;
        }
        .triangle {
          @include make-triangle(left, 7px,$light);
          position: absolute;
          top: 5px;
          left: 1px;
        }
      }
      &__tabs {
        width: 100%;
        height: 80%;
        padding-bottom: 10px;
        >li {
          padding-left: 20px;
          padding-right: 20px;
          text-align: left;
          position: relative;
          >a {
            color: $brand-color-2;
            padding-top: 15px;
            padding-bottom: 15px;
            display: block;
            text-decoration: none;
            cursor: pointer;
          }
          .sidebar__back-button {
            color: $brand-color-1;
            &:hover {
              color: $light;
            }
          }
          &:hover {
            background-color: $brand-color-2;
            a {
              color: $light;
            }
          }
          &:after {
            content: "";
            width: 80%;
            margin: 0 auto;
            height: 1px;
            background-color: white;
            position: absolute;
            top: 100%;
          }
        }
        .active {
          background-color: $light;
          a {
            color: $brand-color-1;
          }
        }
      }
    }
    /*.modal-content {
    	height:500px;
	    padding-top: 0px;
	    padding-bottom: 0px;
	    margin-bottom: 0px;
    	margin-top: 0px;

    }*/
    .modal__content {
      height: 500px;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      &_loader{
      @include make-loader;
      }
    }
    &__header {
      color: $brand-color-2;
      text-transform: uppercase;
      background-color: $brand-color-3;
      padding: $base-padding/3;
      >span {
        float: right;
        text-transform: capitalize;
      }
    }
    .package-detail,
    .package-hotel-highlights,
	.dynamic-package-detail {
      &__sidebar {
        float: left;
        width: 25%;
        height: 100%;
        background-color: $body-bg;
      }
      &__content {
        float: right;
        width: 75%;
        height: 100%;
        overflow: auto;
        margin-top: 0;
        padding: $base-padding/6;
      }
    }
    .package-overview,
    .package-incl-excl,
    .package-itinerary,
    .package-hotel-description,
    .package-canc-policy {
      &__header {
        min-height: 30px;
        background-color: $brand-color-4;
        color: $brand-color-5;
        text-transform: uppercase;
        padding: 5px;
      }
      &__content {
        padding-top: 12px;
        padding-left: 12px;
        padding-bottom: 5px;
 		&_remarks{
 			font-weight:900;
 		}
        .glyphicon {
          color: $star-rating;
        }
      }
      &__amenity {
        padding-top: 12px;
        padding-left: 12px;
        padding-bottom: 1px;
      }
    }
    .package-rate,
    .alternative-hotel {
      @include create-main-wrapper;
      @include create-row;
      @include create-row-card;
      @include create-row-card-footer;
      @include clearfix;
      font-size: $font-size-h3;
      &_card {
        box-shadow: $row-shadow-color;

        //margin-top:$base-margin/3;
        margin-bottom: 0;
        padding-top: $base-padding/2;
        padding-bottom: $base-padding/2;
      }
      &_footer {
        margin: 0;
        background-color: $brand-color-4;
        .package-rate_details {
          @include make-one-fifth;
          background-color: $brand-color-3;
          padding: $base-padding/6;
        }
      }
      &__category {
        @include make-one-fifth;
      }
      &__city {
        @include make-one-fifth;
      }
      &__star-rating {
        @include make-three-fifth;
        .glyphicon {
          color: $star-rating;
        }
      }
      &__total-price {
        @include make-one-fifth;
        color: $brand-color-1;
      }
      &__currency {
        color: $brand-color-5;
      }
      &__book-button {
        @include make-one-fifth;
        background-color: $brand-color-1;
        color: $light;
        padding: $base-padding/6;
      }
    }
    .rate-products {
      border: 5px solid $brand-color-3;
      padding: $base-padding/6;
      &__header {
        @include clearfix();
        border-bottom: 1px solid $brand-color-3;
        text-transform: uppercase;
        background-color: $light;
        color: $brand-color-2;
        font-weight: 400;
        > h4 {
          @include make-one-fifth;
        }
      }
      &__content {
        .content-hotels {
          @include clearfix();
          width: 100%;
          border-bottom: 1px solid $brand-color-4;
          background-color: $light;
          font-weight: 400;
          padding-top: $base-padding/3;
       	  
       	  &__details-row {
       	  	@include clearfix;
       	  
          .hotel {
            &__name,
            &__city,
            &__roomtype,
            &__star-rating,
            &__no-days {
              @include make-one-fifth;
            }
            &__city {
              text-transform: uppercase;
            }
            &__star-rating {
              @include make-one-fifth;
              .glyphicon {
                color: $star-rating;
              }
            }
            &__highlights {
              width: 100%;
              @include clearfix();
              >button {
                @include make-one-fifth;
                color: $brand-color-2;
                background-color: $brand-color-3;
              }
            }
            
       	  }
            
            
          }
        }
      }
    }
  }
}

@mixin  create-package-detail-modal-container { 
	@include create-package-detail-modal-container_private; 
}