@mixin create-modify-search-form_private {

  input[type="text"],
  select {
    width: 100%;
    padding: 0.5rem 1rem;
    background: none;
    font-size: 0.875rem;
    vertical-align: middle;
    line-height: normal;
  }
  input[type="text"] {
    background-color: $input-bgcolor;
    border: 1px solid;
    border-color: $input-border-color;
    max-height: 34px;
  }
  label {
    margin-bottom: 0.25rem;
    color: $sf-label-color;
    font-size: 0.875rem;
  }
  .modal-header {
    margin-left: -$base-margin/2;
    margin-right: -$base-margin/2;
    font-size: $font-size-h3;
    color: $brand-color-2;
    padding-top: 0;
    padding-bottom: $base-padding/3;
  }
  &__header {
    padding-left: 0.9375rem;
  }
  .search__date {
    @include clearfix;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 1rem;
    &_select {
      float: left;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      min-height: 1px;
      width: 50%;
    }
  }
  .search__traveller {
    @include clearfix;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 1rem;
  }
  .room {
    @include clearfix;
    background-color: lighten($brand-color-3,10);
    height: 30px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 5px;
    &__number {
      float: left;
      color: $brand-color-1;
      font-size: $font-size-h4;
    }
    &__close {
      float: right;
      color: $brand-color-5;
      cursor: pointer;
    }
  }
  .passenger-info {
    @include clearfix;
    margin-bottom: 10px;
    &__adult {
      @include make-one-fourth;
    }
    &__children {
      @include make-one-fourth;
    }
    &__age {
      @include make-one-half;
      padding-left: 0;
      padding-right: 0;
      &_heading {
        color: $brand-color-1;
        margin-left: 15px;
      }
      &_selector {
        @include make-one-half;

        //@include make-one-fourth;
      }
    }
  }
  .age__select {
    @include make-one-fourth;
  }
  .room-info {
    @include clearfix;
    margin-bottom: 10px;
    &__type {
      float: left;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      min-height: 1px;
      width: 25%;
    }
    &__extra-bed {
      float: left;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      min-height: 1px;
      width: 75%;
      padding-top: 35px;
    }
  }
  .add-room {
    height: 30px;
    color: $brand-color-2;
    background-color: lighten($brand-color-3,10);
    font-size: $font-size-h4;
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
    &__heading {

      //float:left;
      margin-right: 10px;
    }
    &__symbol {

      //float:right;
    }
  }

  // .search__submit {
  //   margin-left: -15px;
  //   margin-right: -15px;
  //   &_button {
  //     float: left;
  //     padding-left: 0.9375rem;
  //     padding-right: 0.9375rem;
  //     min-height: 1px;
  //     width: 50%;
  //     float: right;
  //     .submit_button {
  //       padding: 0.375rem 0.9375rem;
  //       font-size: 1rem;
  //       line-height: 1.5;
  //       -webkit-border-radius: 0;
  //       border-radius: 0;
  //       color: #fff;
  //       background-color: #ec2227;
  //       text-transform: uppercase;
  //       width: 100%;
  //     }
  //   }
  // }
}

@mixin  create-modify-search-form { 
	@include create-modify-search-form_private; 
}