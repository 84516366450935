@import url(https://fonts.googleapis.com/css?family=Raleway:700,500,600|Open+Sans:400,600,700);
@media print {
  .search__result_wrapper {
    display: none;
  }
}
.search-location-checkbox {
  padding-top: 0.2375rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.multi-select-dropdown {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  position: relative;
}
.multi-select-dropdown .glyphicon-ok {
  width: 8%;
  float: right;
  font-size: 1rem;
}

.package__search-form-container {
  background-image: url(../../../images/hero-image-3.jpg);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-size: cover;
  background-position: center left;
  min-height: 100vh;
}
.package__search-form-container_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.package__search-form-container_main:before, .package__search-form-container_main:after {
  content: " ";
  display: table;
}
.package__search-form-container_main:after {
  clear: both;
}
.package__search-form-container_main .content {
  padding-left: 0;
}
.package__search-form-container_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .package__search-form-container_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .package__search-form-container_wrapper {
    max-width: 1400px;
  }
}
.package__search-form-container_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.package__search-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-wrapper:before, .package__search-wrapper:after {
  content: " ";
  display: table;
}
.package__search-wrapper:after {
  clear: both;
}
.package__search-form {
  background-color: #fff;
  padding: 1.875rem;
  margin: 0 auto;
  min-height: 300px;
}
.package__search-form_header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.package__page-header {
  color: #fff;
  padding-bottom: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-top: 0;
}
.package__search-trip-type, .package__search-location, .package__search-date, .package__search-traveller, .package__search-multicity, .package__search-nationality, .package__search-residence-country, .package__search-currency, .package__additioal-option, .package__pnr-search-location, .package__product-selection, .package__advance-search, .package__advance-search-params, .package__search-supplier, .package__search-modify-journey {
  margin-bottom: 1rem;
}
.package__with-baggage_label {
  padding: 0.3125rem;
}
.package__pnr-fields {
  margin-left: -15px;
  margin-right: -15px;
}
.package__pnr-fields:before, .package__pnr-fields:after {
  content: " ";
  display: table;
}
.package__pnr-fields:after {
  clear: both;
}
.package__pnr-search-location .input-container {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  margin-bottom: 1rem;
}
.package__search-trip-type, .package__search-modify-journey {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.package__search-trip-type:before, .package__search-trip-type:after, .package__search-modify-journey:before, .package__search-modify-journey:after {
  content: " ";
  display: table;
}
.package__search-trip-type:after, .package__search-modify-journey:after {
  clear: both;
}
.package__search-trip-type .radio-inline, .package__search-modify-journey .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: auto;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .package__search-trip-type .radio-inline, .package__search-modify-journey .radio-inline {
    width: 16.6666666667%;
  }
}
.package__search-trip-type .radio-inline input[disabled], .package__search-modify-journey .radio-inline input[disabled] {
  cursor: not-allowed;
}
.package__search-modify-journey .modify-journey {
  float: right;
  padding-right: 0.8rem;
}
.package__search-modify-journey .modify-journey__button {
  background: transparent;
  font-weight: 600;
}
.package__search-location {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-location:before, .package__search-location:after {
  content: " ";
  display: table;
}
.package__search-location:after {
  clear: both;
}
.package__search-location .search-location_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.package__search-location .search-location_search-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.package__search-date, .package__hub-surcharge, .package__search-date_hotel-dates {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-date:before, .package__search-date:after, .package__hub-surcharge:before, .package__hub-surcharge:after, .package__search-date_hotel-dates:before, .package__search-date_hotel-dates:after {
  content: " ";
  display: table;
}
.package__search-date:after, .package__hub-surcharge:after, .package__search-date_hotel-dates:after {
  clear: both;
}
.package__search-date .search-date_select, .package__search-date_baggage-one-way, .package__hub-surcharge .search-date_select, .package__hub-surcharge_baggage-one-way, .package__search-date_hotel-dates .search-date_select, .package__search-date_hotel-dates_baggage-one-way {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.package__search-date_baggage-one-way .baggage-label, .package__hub-surcharge_baggage-one-way .baggage-label, .package__search-date_hotel-dates_baggage-one-way .baggage-label {
  margin-top: 1.75rem;
  padding: 0.3125rem;
}
.package__search-date_baggage-round-multicity .baggage-label, .package__hub-surcharge_baggage-round-multicity .baggage-label, .package__search-date_hotel-dates_baggage-round-multicity .baggage-label {
  padding: 0.3125rem;
}
.package__search-date_hotel-dates .search-date_select, .package__hub-surcharge_hotel-dates .search-date_select, .package__search-date_hotel-dates_hotel-dates .search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  margin-right: -13px;
  width: 35%;
}
.package__search-date .date-field-with-duration, .package__hub-surcharge .date-field-with-duration, .package__search-date_hotel-dates .date-field-with-duration {
  width: 40%;
}
.package__search-date-verticle {
  margin-left: -15px;
  margin-right: -15px;
  width: 50%;
  float: left;
  padding-right: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}
.package__search-date-verticle:before, .package__search-date-verticle:after {
  content: " ";
  display: table;
}
.package__search-date-verticle:after {
  clear: both;
}
.package__search-date-verticle .date-range-picker__from-date, .package__search-date-verticle .date-range-picker__to-date {
  margin-bottom: 1rem;
}
.package__hub-surcharge-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.package__hub-surcharge-container:before, .package__hub-surcharge-container:after {
  content: " ";
  display: table;
}
.package__hub-surcharge-container:after {
  clear: both;
}
.package__search-nationality, .package__search-residence-country {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-nationality:before, .package__search-nationality:after, .package__search-residence-country:before, .package__search-residence-country:after {
  content: " ";
  display: table;
}
.package__search-nationality:after, .package__search-residence-country:after {
  clear: both;
}
.package__search-currency {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-currency:before, .package__search-currency:after {
  content: " ";
  display: table;
}
.package__search-currency:after {
  clear: both;
}
.package__air-select-nationality {
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
}
.package__search-multicity-row, .package__search-multicity_hotel-dates, .package__search-multicity_hotel-search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-multicity-row:before, .package__search-multicity-row:after, .package__search-multicity_hotel-dates:before, .package__search-multicity_hotel-dates:after, .package__search-multicity_hotel-search-date:before, .package__search-multicity_hotel-search-date:after {
  content: " ";
  display: table;
}
.package__search-multicity-row:after, .package__search-multicity_hotel-dates:after, .package__search-multicity_hotel-search-date:after {
  clear: both;
}
.package__search-multicity_hotel-dates .hotel-dates__room-info_extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 7px;
  width: 75%;
}
.package__search-multicity_hotel-dates .hotel-dates__search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-multicity_location {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  width: 70%;
}
.package__search-multicity_location .multicity_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0px;
}
.package__search-multicity_location .multicity_autosuggestion .search-location_autosuggestion .itemcontainer {
  left: 0px;
  right: 0px;
}
.package__search-multicity_location-segmentwise-cabin-class {
  width: 50%;
  padding-right: 0rem;
}
.package__search-multicity_location-segmentwise-modify {
  width: 60%;
  padding-right: 0rem;
}
.package__search-multicity_date {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  width: 28%;
}
.package__search-multicity_date .search-date_select {
  width: 100%;
}
.package__search-multicity_date-segmentwise-cabin-class {
  padding-left: 0rem;
}
.package__search-multicity_cabin-class {
  float: left;
  width: 20%;
}
.package__search-multicity_flight-close {
  float: right;
  color: #838383;
  padding-top: 32px;
  cursor: pointer;
}
.package__search-multicity_modify {
  float: left;
  width: 10%;
  display: block;
  padding-top: 2rem;
  font-size: 0.875rem;
}
.package__search-multicity_modify button {
  font-weight: 600;
  background: transparent;
  color: #ec2227;
  padding: 0;
}
.package__search-multicity_modify button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.package__search-traveller {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-traveller:before, .package__search-traveller:after {
  content: " ";
  display: table;
}
.package__search-traveller:after {
  clear: both;
}
.package__search-traveller:before, .package__search-traveller:after {
  content: " ";
  display: table;
}
.package__search-traveller:after {
  clear: both;
}
.package__search-traveller_adult, .package__search-traveller_child, .package__search-traveller_infant, .package__search-traveller_class {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.package__search-traveller_adult input, .package__search-traveller_child input, .package__search-traveller_infant input, .package__search-traveller_class input {
  text-align: center;
}
.package__search-traveller_round-trip-class {
  width: 50%;
  display: table;
}
.package__search-traveller_round-trip-class .round-trip-class__cabin-class {
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
  width: 100%;
}
.package__search-traveller_luggage {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
}
.package__advance-search_title {
  background-color: #f3f9fd;
  padding: 0.5rem 0.5rem 0rem;
  cursor: pointer;
}
.package__advance-search-params {
  margin-left: -15px;
  margin-right: -15px;
}
.package__advance-search-params:before, .package__advance-search-params:after {
  content: " ";
  display: table;
}
.package__advance-search-params:after {
  clear: both;
}
.package__advance-search-params_stops, .package__advance-search-params_refundable {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.package__search-supplier {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 1rem;
}
.package__search-supplier:before, .package__search-supplier:after {
  content: " ";
  display: table;
}
.package__search-supplier:after {
  clear: both;
}
.package__supplier-selection label {
  padding-right: 2rem;
}
.package__additioal-option {
  margin-left: -15px;
  margin-right: -15px;
}
.package__additioal-option:before, .package__additioal-option:after {
  content: " ";
  display: table;
}
.package__additioal-option:after {
  clear: both;
}
.package__additioal-option_content {
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 5px;
}
.package__search-submit {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search-submit:before, .package__search-submit:after {
  content: " ";
  display: table;
}
.package__search-submit:after {
  clear: both;
}
.package__search-submit_button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.package__search-submit_button .submit_button {
  padding: 0.375rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
}
.package__search-submit_button .submit_button:focus, .package__search-submit_button .submit_button.focus, .package__search-submit_button .submit_button:active:focus, .package__search-submit_button .submit_button:active.focus, .package__search-submit_button .submit_button.active:focus, .package__search-submit_button .submit_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.package__search-submit_button .submit_button:hover, .package__search-submit_button .submit_button:focus, .package__search-submit_button .submit_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.package__search-submit_button .submit_button:active, .package__search-submit_button .submit_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.package__search-submit_button .submit_button.disabled, .package__search-submit_button .submit_button[disabled], fieldset[disabled] .package__search-submit_button .submit_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.package__search-form_container input[type=text], .package__search-form_container input[type=number],
.package__search-form_container select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.package__search-form_container input[type=text], .package__search-form_container input[type=number] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.package__search-form_container .number-stepper {
  max-width: 250px;
}
.package__search-form_container .number-stepper input[type=text] {
  text-align: center;
}
.package__search-form_container .number-stepper button[disabled] {
  cursor: not-allowed;
  color: #818a91;
}
.package__search-form_container .number-stepper button[disabled]:hover {
  background-color: #818a91;
  color: #fff;
}
.package__search-form_container label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.package__advertise {
  padding: 1.875rem;
  background-color: #fff;
  margin-top: 2.375rem;
  min-height: 300px;
}
.package__advertise p {
  margin-bottom: 0;
}
.package__search-time, .package__search-date, .package__input-field {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.package__search-time:before, .package__search-time:after, .package__search-date:before, .package__search-date:after, .package__input-field:before, .package__input-field:after {
  content: " ";
  display: table;
}
.package__search-time:after, .package__search-date:after, .package__input-field:after {
  clear: both;
}
.package__search-time_select, .package__search-date_select, .package__input-field_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.package__search-time_half, .package__search-date_half, .package__input-field_half {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.package__search-nav {
  background-color: #087dc2;
}
.package__search-nav:before, .package__search-nav:after {
  content: " ";
  display: table;
}
.package__search-nav:after {
  clear: both;
}
.package__search-nav_list li {
  float: left;
  background-color: #087dc2;
  border-right: 2px solid;
  border-color: #fff;
}
.package__search-nav_list li a {
  padding: 0.625rem 0.625rem;
  display: block;
  color: #fff;
  text-transform: uppercase;
}
.package__search-nav_list li a:hover {
  background-color: #ec2227;
  color: #fff;
  text-decoration: none;
}
.package__search-nav_list .active {
  background-color: #ec2227;
}
.package__search-nav_list .active a {
  color: #fff;
}
@media (max-width: 991px) {
  .package__search-nav_list {
    display: none;
  }
}

.no-of-nights-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.no-of-nights-card__header {
  padding-bottom: 0.4375rem;
  border-bottom: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.no-of-nights-card__table {
  font-family: "Open Sans";
  width: 100%;
}
.no-of-nights-card__table tr:last-child {
  background-color: #f0f5f8;
}
.no-of-nights-card__table td {
  border-bottom: 1px solid #b6b6b6;
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  font-weight: 600;
}
.no-of-nights-card__table td:last-child {
  text-align: right;
}
.guest-occupancy_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.guest-occupancy_main:before, .guest-occupancy_main:after {
  content: " ";
  display: table;
}
.guest-occupancy_main:after {
  clear: both;
}
.guest-occupancy_main .content {
  padding-left: 0;
}
.guest-occupancy_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .guest-occupancy_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .guest-occupancy_wrapper {
    max-width: 1400px;
  }
}
.guest-occupancy_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.guest-occupancy_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.guest-occupancy_row:before, .guest-occupancy_row:after {
  content: " ";
  display: table;
}
.guest-occupancy_row:after {
  clear: both;
}
.guest-occupancy_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.guest-occupancy_card:before, .guest-occupancy_card:after {
  content: " ";
  display: table;
}
.guest-occupancy_card:after {
  clear: both;
}
.guest-occupancy_card_header {
  padding: 0.375rem 0.9375rem;
}
.guest-occupancy_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.guest-occupancy_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.guest-occupancy_header ul:before, .guest-occupancy_header ul:after {
  content: " ";
  display: table;
}
.guest-occupancy_header ul:after {
  clear: both;
}
.guest-occupancy_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.guest-occupancy_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.guest-occupancy_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.guest-occupancy_card_content {
  margin-bottom: 0.9375rem;
}
.guest-occupancy_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.guest-occupancy_footer:before, .guest-occupancy_footer:after {
  content: " ";
  display: table;
}
.guest-occupancy_footer:after {
  clear: both;
}
.guest-occupancy_hotel {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.guest-occupancy_from, .guest-occupancy_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.guest-occupancy_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.guest-occupancy_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.guest-occupancy__content {
  width: 100%;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.guest-occupancy__content:before, .guest-occupancy__content:after {
  content: " ";
  display: table;
}
.guest-occupancy__content:after {
  clear: both;
}
.guest-occupancy__content td {
  width: 15%;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0.3125rem;
  /*&:last-child {
    text-align: right;
  }*/
  padding-bottom: 0.9375rem;
}
.guest-occupancy__content_icon {
  color: #FFA500;
}
.guest-occupancy__content_highlight {
  color: #ec2227;
}
.guest-occupancy__title {
  text-transform: uppercase;
  color: #087dc2;
  padding-top: 0.9375rem;
  padding-bottom: 0.375rem;
  padding-right: 0.375rem;
  padding-left: 0;
}
.guest-occupancy__title > span {
  color: #087dc2;
  float: right;
  text-transform: uppercase;
  font-size: 0.625rem;
}
.guest-occupancy_total {
  background-color: #f0f5f8;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
}
.guest-occupancy_total td {
  font-weight: 700;
}
.guest-occupancy__info > table {
  width: 100%;
}
.guest-occupancy__header {
  background-color: #f0f5f8;
  text-transform: uppercase;
}
.guest-occupancy__header th {
  width: 15%;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 0.3125rem;
  /*&:last-child {
    text-align: right;
  }*/
}
.guest-occupancy__header tr {
  border-bottom: 0.125rem solid #f0f5f8;
}
.guest-occupancy__notification {
  color: #ec2227;
}

.package__review_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.package__review_main:before, .package__review_main:after {
  content: " ";
  display: table;
}
.package__review_main:after {
  clear: both;
}
.package__review_main .content {
  padding-left: 0;
}
.package__review_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .package__review_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .package__review_wrapper {
    max-width: 1400px;
  }
}
.package__review_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.package__review_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.package__review_row:before, .package__review_row:after {
  content: " ";
  display: table;
}
.package__review_row:after {
  clear: both;
}
.package__review_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.package__review_card:before, .package__review_card:after {
  content: " ";
  display: table;
}
.package__review_card:after {
  clear: both;
}
.package__review_card_header {
  padding: 0.375rem 0.9375rem;
}
.package__review_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.package__review_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.package__review_header ul:before, .package__review_header ul:after {
  content: " ";
  display: table;
}
.package__review_header ul:after {
  clear: both;
}
.package__review_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.package__review_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.package__review_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.package__review_card_content {
  margin-bottom: 0.9375rem;
}
.package__review_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.package__review_footer:before, .package__review_footer:after {
  content: " ";
  display: table;
}
.package__review_footer:after {
  clear: both;
}
.package__review_package {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package__review_from, .package__review_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.package__review_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package__review_main {
  padding-top: 0.9375rem;
}
.package__review_main .content {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.package__review_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.package__review_card_title {
  text-transform: uppercase;
  color: #087dc2;
  padding: 0.9375rem 0 0.375rem 0.375rem;
}
.package__review_card_header {
  background-color: #f0f5f8;
  text-transform: uppercase;
}
.package__review_card .nav {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  background-color: #c7e5f6;
}
.package__review_card .nav-tabs {
  border-bottom: none;
}
.package__review_card .nav-tabs > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  color: #087dc2;
  font-size: 0.875rem;
}
.package__review_card .nav > li > a:hover,
.package__review_card .nav > li > a:focus {
  background-color: #fff;
}
.package__review_card .nav-tabs > li.active > a,
.package__review_card .nav-tabs > li.active > a:hover,
.package__review_card .nav-tabs > li.active > a:focus {
  color: #087dc2;
  background-color: #fff;
  border: none;
  cursor: default;
}
.package__review_progress {
  margin-left: -15px;
  margin-right: -15px;
  max-width: 75%;
  counter-reset: step -1;
  margin-bottom: 0.9375rem;
}
.package__review_progress:before, .package__review_progress:after {
  content: " ";
  display: table;
}
.package__review_progress:after {
  clear: both;
}
.package__review_progress > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  font-size: 0.625rem;
}
.package__review_progress > li > span {
  display: inline-block;
  color: #087dc2;
}
.package__review_progress > li > span:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  line-height: 30px;
  border-radius: 100%;
  background-color: #fff;
  display: block;
  font-size: 1rem;
  margin: 0 auto;
  color: #b6b6b6;
}
.package__review_progress > li > span:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #b6b6b6;
  position: absolute;
  left: -50%;
  top: 15px;
  z-index: -1;
}
.package__review_progress > li > span.active {
  color: #000;
}
.package__review_progress > li > span.active:before {
  background-color: #087dc2;
  color: #fff;
}
.package__review_progress > li:first-child > span {
  color: inherit;
  cursor: pointer;
}
.package__review_progress > li:first-child > span:before {
  content: "<";
  background-color: #b6b6b6;
  color: #fff;
}
.package__review_progress > li:first-child > span:after {
  content: none;
}
.package__review_progress > li:first-child > span:hover:before {
  background-color: #ec2227;
}
.package__review_progress > li:first-child > span:hover {
  color: #ec2227;
}
.package__review_name {
  background-color: #fff;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.package__review_name > div {
  color: #087dc2;
  font-size: 1.125rem;
  padding: 0.625rem;
}

.package__remark_heading,
.package__inclusion_heading,
.package__exclusion_heading,
.package__cancellation_heading {
  font-size: 0.875rem;
  color: #087dc2;
  padding-top: 0.9375rem;
  padding-bottom: 0.625rem;
}

.rate-category-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.rate-category-card__header {
  padding-bottom: 0.4375rem;
  border-bottom: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.rate-category-card__table {
  font-family: "Open Sans";
  width: 100%;
}
.rate-category-card__table td {
  border-bottom: 1px solid #b6b6b6;
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  font-weight: 600;
}
.hotel-detail_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.hotel-detail_main:before, .hotel-detail_main:after {
  content: " ";
  display: table;
}
.hotel-detail_main:after {
  clear: both;
}
.hotel-detail_main .content {
  padding-left: 0;
}
.hotel-detail_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .hotel-detail_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .hotel-detail_wrapper {
    max-width: 1400px;
  }
}
.hotel-detail_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.hotel-detail_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.hotel-detail_row:before, .hotel-detail_row:after {
  content: " ";
  display: table;
}
.hotel-detail_row:after {
  clear: both;
}
.hotel-detail_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.hotel-detail_card:before, .hotel-detail_card:after {
  content: " ";
  display: table;
}
.hotel-detail_card:after {
  clear: both;
}
.hotel-detail_card_header {
  padding: 0.375rem 0.9375rem;
}
.hotel-detail_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.hotel-detail_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.hotel-detail_header ul:before, .hotel-detail_header ul:after {
  content: " ";
  display: table;
}
.hotel-detail_header ul:after {
  clear: both;
}
.hotel-detail_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.hotel-detail_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.hotel-detail_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.hotel-detail_card_content {
  margin-bottom: 0.9375rem;
}
.hotel-detail_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.hotel-detail_footer:before, .hotel-detail_footer:after {
  content: " ";
  display: table;
}
.hotel-detail_footer:after {
  clear: both;
}
.hotel-detail_hotel {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.hotel-detail_from, .hotel-detail_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.hotel-detail_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.hotel-detail_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.hotel-detail__content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
  margin-top: 0px;
  min-height: calc(1.875rem/3 + 80px);
}
.hotel-detail__title {
  text-transform: uppercase;
  color: #087dc2;
  padding: 0.9375rem 0 0.375rem 0.375rem;
  padding-left: 0px;
  width: 50%;
}
.hotel-detail__date {
  width: 50%;
  float: right;
}
.hotel-detail__header {
  width: 100%;
  background-color: #f0f5f8;
  padding: 0.3125rem;
  text-transform: uppercase;
}
.hotel-detail__header:before, .hotel-detail__header:after {
  content: " ";
  display: table;
}
.hotel-detail__header:after {
  clear: both;
}
.hotel-detail__header .header-empty {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.hotel-detail__header .header-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
  padding-left: 0;
}
.hotel-detail__header .header-text:before, .hotel-detail__header .header-text:after {
  content: " ";
  display: table;
}
.hotel-detail__header .header-text:after {
  clear: both;
}
.hotel-detail__header .header-text .header_text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  padding-left: 3px;
  text-align: left;
}
.hotel-detail__header .header-text .header_text:last-child {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
  padding-left: 1.7rem;
}

.content__image {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.content__image:before, .content__image:after {
  content: " ";
  display: table;
}
.content__image:after {
  clear: both;
}
.content__image > img {
  width: 100%;
  max-height: 80px;
}
.content__description, .content__extra-desc {
  padding-top: 0.625rem;
  width: 100%;
}
.content__description:before, .content__description:after, .content__extra-desc:before, .content__extra-desc:after {
  content: " ";
  display: table;
}
.content__description:after, .content__extra-desc:after {
  clear: both;
}
.content__description {
  border-bottom: 2px solid #f0f5f8;
  padding-bottom: 0.625rem;
}

.description__hotel {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  padding-left: 0;
}
.description__hotel_name, .description__hotel_nights {
  max-height: 100px;
  text-align: left;
  font-family: "Open Sans";
  color: #087dc2;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
}
.description__hotel_nights {
  color: #838383;
  font-size: 12px;
  font-weight: 600;
}
.description__rating {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
  padding-right: 0;
  max-height: 100px;
}
.description__rating .glyphicon {
  color: #F3C103;
}

.extra-desc__country {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  padding-left: 0;
  text-transform: capitalize;
}
.extra-desc__country_name {
  color: #838383;
  max-height: 100px;
  text-align: left;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
}
.extra-desc__room {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
  padding-right: 0;
}
.extra-desc__room-type {
  color: #838383;
  text-transform: capitalize;
}

.package__search_result .search-info {
  background-color: #087dc2;
}
.package__search_result .search-info_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.package__search_result .search-info_main:before, .package__search_result .search-info_main:after {
  content: " ";
  display: table;
}
.package__search_result .search-info_main:after {
  clear: both;
}
.package__search_result .search-info_main .content {
  padding-left: 0;
}
.package__search_result .search-info_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .package__search_result .search-info_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .package__search_result .search-info_wrapper {
    max-width: 1400px;
  }
}
.package__search_result .search-info_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.package__search_result .search-info_wrapper:before, .package__search_result .search-info_wrapper:after {
  content: " ";
  display: table;
}
.package__search_result .search-info_wrapper:after {
  clear: both;
}
.package__search_result .search-info__data {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  float: left;
  color: #f0f5f8;
  font-size: 1rem;
  display: block;
}
.package__search_result .search-info__modify {
  padding-top: 1px;
  padding-bottom: 1px;
  float: right;
}
.package__search_result .search-info__modify_button {
  padding: 0.3125rem 0.9375rem;
  font-size: 1rem;
  line-height: inherit;
  border-radius: 0;
  color: #087dc2;
  background-color: #c7e5f6;
}
.package__search_result .search-info__modify_button:focus, .package__search_result .search-info__modify_button.focus, .package__search_result .search-info__modify_button:active:focus, .package__search_result .search-info__modify_button:active.focus, .package__search_result .search-info__modify_button.active:focus, .package__search_result .search-info__modify_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.package__search_result .search-info__modify_button:hover, .package__search_result .search-info__modify_button:focus, .package__search_result .search-info__modify_button.focus {
  color: #087dc2;
  text-decoration: none;
  background-color: #9bd1ef;
}
.package__search_result .search-info__modify_button:active, .package__search_result .search-info__modify_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.package__search_result .search-info__modify_button.disabled, .package__search_result .search-info__modify_button[disabled], fieldset[disabled] .package__search_result .search-info__modify_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.package__search_result .search-details {
  background-color: #fff;
}
.package__search_result .search-details_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.package__search_result .search-details_main:before, .package__search_result .search-details_main:after {
  content: " ";
  display: table;
}
.package__search_result .search-details_main:after {
  clear: both;
}
.package__search_result .search-details_main .content {
  padding-left: 0;
}
.package__search_result .search-details_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .package__search_result .search-details_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .package__search_result .search-details_wrapper {
    max-width: 1400px;
  }
}
.package__search_result .search-details_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.package__search_result .search-details_wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}
.package__search_result .search-details__row:before, .package__search_result .search-details__row:after {
  content: " ";
  display: table;
}
.package__search_result .search-details__row:after {
  clear: both;
}
.package__search_result .search-details__row > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  padding-left: 0;
  padding-right: 0;
  border-right: 2px solid #c7e5f6;
}
.package__search_result .search-details__row > li:first-child {
  border-left: 2px solid #c7e5f6;
}
.package__search_result .search-details__row > li:nth-child(3) {
  border-right: none;
}
.package__search_result .search-details__row > li:last-child {
  padding-right: 0.9375rem;
  border-right: none;
}
.package__search_result .search-details__row_search-parameters {
  margin-right: -15px;
}
.package__search_result .search-details .from-date__data,
.package__search_result .search-details .to-date__data,
.package__search_result .search-details .destination__data {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.package__search_result .search-details .from-date__data:before, .package__search_result .search-details .from-date__data:after,
.package__search_result .search-details .to-date__data:before,
.package__search_result .search-details .to-date__data:after,
.package__search_result .search-details .destination__data:before,
.package__search_result .search-details .destination__data:after {
  content: " ";
  display: table;
}
.package__search_result .search-details .from-date__data:after,
.package__search_result .search-details .to-date__data:after,
.package__search_result .search-details .destination__data:after {
  clear: both;
}
.package__search_result .search-details .from-date__data_icon,
.package__search_result .search-details .to-date__data_icon,
.package__search_result .search-details .destination__data_icon {
  float: left;
  font-size: 2.25rem;
  width: 20%;
}
.package__search_result .search-details .from-date__data_detail,
.package__search_result .search-details .to-date__data_detail,
.package__search_result .search-details .destination__data_detail {
  float: left;
  padding: 9px;
  width: 80%;
}
.package__search_result .search-details .from-date__data_detail .location,
.package__search_result .search-details .to-date__data_detail .location,
.package__search_result .search-details .destination__data_detail .location {
  color: #087dc2;
}
.package__search_result .search-details .from-date__data_detail .departure:before, .package__search_result .search-details .from-date__data_detail .departure:after,
.package__search_result .search-details .to-date__data_detail .departure:before,
.package__search_result .search-details .to-date__data_detail .departure:after,
.package__search_result .search-details .destination__data_detail .departure:before,
.package__search_result .search-details .destination__data_detail .departure:after {
  content: " ";
  display: table;
}
.package__search_result .search-details .from-date__data_detail .departure:after,
.package__search_result .search-details .to-date__data_detail .departure:after,
.package__search_result .search-details .destination__data_detail .departure:after {
  clear: both;
}
.package__search_result .search-details .from-date__data_detail .departure .departure__heading,
.package__search_result .search-details .to-date__data_detail .departure .departure__heading,
.package__search_result .search-details .destination__data_detail .departure .departure__heading {
  float: left;
}
.package__search_result .search-details .from-date__data_detail .departure .departure__date,
.package__search_result .search-details .to-date__data_detail .departure .departure__date,
.package__search_result .search-details .destination__data_detail .departure .departure__date {
  float: left;
}
.package__search_result .search-details .search-parameters__row:before, .package__search_result .search-details .search-parameters__row:after {
  content: " ";
  display: table;
}
.package__search_result .search-details .search-parameters__row:after {
  clear: both;
}
.package__search_result .search-details .search-parameters__row > li {
  text-align: center;
  width: 25%;
  border-left: 2px solid #c7e5f6;
  padding-top: 9px;
  padding-bottom: 9px;
  float: left;
}
.package__search_result .search-details .search-parameters__row > li:last-child {
  border-right: 2px solid #c7e5f6;
}
.package__search_result .destination__data_icon span.glyphicon.glyphicon-arrow-right,
.package__search_result .destination__data_icon span.glyphicon.glyphicon-arrow-left,
.package__search_result .destination__data_icon span.glyphicon.glyphicon-map-marker,
.package__search_result .from-date__data_icon span.glyphicon.glyphicon-arrow-right,
.package__search_result .from-date__data_icon span.glyphicon.glyphicon-arrow-left,
.package__search_result .from-date__data_icon span.glyphicon.glyphicon-map-marker,
.package__search_result .to-date__data_icon span.glyphicon.glyphicon-arrow-right,
.package__search_result .to-date__data_icon span.glyphicon.glyphicon-arrow-left,
.package__search_result .to-date__data_icon span.glyphicon.glyphicon-map-marker {
  top: 5px;
}

.passenger-info-modal input[type=text],
.passenger-info-modal select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
}
.passenger-info-modal input[type=text] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.passenger-info-modal label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.passenger-info-modal .modal-header {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  font-size: 1.125rem;
  color: #087dc2;
  padding-top: 0;
  padding-bottom: 0.625rem;
}
.passenger-info-modal__header {
  padding-left: 0.9375rem;
}
.passenger-info-modal .search__date {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.passenger-info-modal .search__date:before, .passenger-info-modal .search__date:after {
  content: " ";
  display: table;
}
.passenger-info-modal .search__date:after {
  clear: both;
}
.passenger-info-modal .search__date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.passenger-info-modal .search__traveller {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.passenger-info-modal .search__traveller:before, .passenger-info-modal .search__traveller:after {
  content: " ";
  display: table;
}
.passenger-info-modal .search__traveller:after {
  clear: both;
}
.passenger-info-modal .room {
  background-color: #f3f9fd;
  height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 5px;
}
.passenger-info-modal .room:before, .passenger-info-modal .room:after {
  content: " ";
  display: table;
}
.passenger-info-modal .room:after {
  clear: both;
}
.passenger-info-modal .room__number {
  float: left;
  color: #ec2227;
  font-size: 0.875rem;
}
.passenger-info-modal .room__close {
  float: right;
  color: #838383;
  cursor: pointer;
}
.passenger-info-modal .passenger-info {
  margin-bottom: 10px;
}
.passenger-info-modal .passenger-info:before, .passenger-info-modal .passenger-info:after {
  content: " ";
  display: table;
}
.passenger-info-modal .passenger-info:after {
  clear: both;
}
.passenger-info-modal .passenger-info__adult {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.passenger-info-modal .passenger-info__children {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.passenger-info-modal .passenger-info__age {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0;
  padding-right: 0;
}
.passenger-info-modal .passenger-info__age_heading {
  color: #ec2227;
  margin-left: 15px;
}
.passenger-info-modal .passenger-info__age_selector {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.passenger-info-modal .age__select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.passenger-info-modal .room-info {
  margin-bottom: 10px;
}
.passenger-info-modal .room-info:before, .passenger-info-modal .room-info:after {
  content: " ";
  display: table;
}
.passenger-info-modal .room-info:after {
  clear: both;
}
.passenger-info-modal .room-info__type {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.passenger-info-modal .room-info__extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  padding-top: 35px;
}
.passenger-info-modal .add-room {
  height: 30px;
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 5px;
  cursor: pointer;
}
.passenger-info-modal .add-room__heading {
  margin-right: 10px;
}
.package__search_result .fp-search__result_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.package__search_result .fp-search__result_main:before, .package__search_result .fp-search__result_main:after {
  content: " ";
  display: table;
}
.package__search_result .fp-search__result_main:after {
  clear: both;
}
.package__search_result .fp-search__result_main .content {
  padding-left: 0;
}
.package__search_result .fp-search__result_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .package__search_result .fp-search__result_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .package__search_result .fp-search__result_wrapper {
    max-width: 1400px;
  }
}
.package__search_result .fp-search__result_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.package__search_result .fp-search__result_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.package__search_result .fp-search__result_row:before, .package__search_result .fp-search__result_row:after {
  content: " ";
  display: table;
}
.package__search_result .fp-search__result_row:after {
  clear: both;
}
.package__search_result .fp-search__result_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.package__search_result .fp-search__result_card:before, .package__search_result .fp-search__result_card:after {
  content: " ";
  display: table;
}
.package__search_result .fp-search__result_card:after {
  clear: both;
}
.package__search_result .fp-search__result_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.package__search_result .fp-search__result_footer:before, .package__search_result .fp-search__result_footer:after {
  content: " ";
  display: table;
}
.package__search_result .fp-search__result_footer:after {
  clear: both;
}
.package__search_result .fp-search__result_package, .package__search_result .fp-search__result_price {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.625rem;
}
.package__search_result .fp-search__result_package:before, .package__search_result .fp-search__result_package:after, .package__search_result .fp-search__result_price:before, .package__search_result .fp-search__result_price:after {
  content: " ";
  display: table;
}
.package__search_result .fp-search__result_package:after, .package__search_result .fp-search__result_price:after {
  clear: both;
}
.package__search_result .fp-search__result_row {
  position: relative;
}
.package__search_result .fp-search__result_tax-header {
  margin-top: -22px;
  margin-left: 19.1rem;
  margin-right: 15px;
  padding: 0.25rem;
  color: #1d771d;
  background-color: #F5D76E;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}
.package__search_result .fp-search__result_tax-header:before, .package__search_result .fp-search__result_tax-header:after {
  content: " ";
  display: table;
}
.package__search_result .fp-search__result_tax-header:after {
  clear: both;
}
.package__search_result .fp-search__result_tax-header .tax-message {
  font-size: 0.9rem;
  padding-left: 0.25rem;
}
.package__search_result .fp-search__result_detail {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #c7e5f6;
  min-height: calc(1.875rem/3 + 80px);
}
.package__search_result .fp-search__result_description, .package__search_result .fp-search__result_extra-desc {
  padding-top: 0.625rem;
  width: 100%;
}
.package__search_result .fp-search__result_description:before, .package__search_result .fp-search__result_description:after, .package__search_result .fp-search__result_extra-desc:before, .package__search_result .fp-search__result_extra-desc:after {
  content: " ";
  display: table;
}
.package__search_result .fp-search__result_description:after, .package__search_result .fp-search__result_extra-desc:after {
  clear: both;
}
.package__search_result .fp-search__result_description {
  padding-bottom: 0.625rem;
}
.package__search_result .fp-search__result_footer {
  background-color: #f0f5f8;
}
.package__search_result .description__package {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  padding-left: 0;
}
.package__search_result .description__package_name, .package__search_result .description__package_duration {
  max-height: 100px;
  text-align: left;
  font-family: "Open Sans";
  color: #087dc2;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
}
.package__search_result .description__package_duration {
  color: #838383;
  font-size: 12px;
  font-weight: 600;
}
.package__search_result .description__rating {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
  padding-right: 0px;
  max-height: 100px;
}
.package__search_result .description__rating .glyphicon {
  color: #F3C103;
}
.package__search_result .package__rate-category {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
  padding-right: 0px;
}
.package__search_result .package__rate-category_text {
  color: #838383;
  text-transform: capitalize;
}
.package__search_result .result__extra_details {
  font-size: 0.625rem;
}
.package__search_result .result__extra_details_supplier {
  float: left;
  width: 30%;
  color: #087dc2;
}
.package__search_result .result__extra_details_wrapper {
  float: right;
  width: 70%;
}
.package__search_result .result__extra_details_wrapper .extra_details-content {
  float: right;
}
.package__search_result .result__extra_details_wrapper .extra_details-content__package-details {
  cursor: pointer;
  padding: 0.3125rem 3.5rem;
  background-color: #c7e5f6;
  float: left;
}
.package__search_result .result__extra_details_wrapper .extra_details-content__package-details > a {
  text-transform: uppercase;
  color: #087dc2;
}
.package__search_result .result__extra_details_wrapper .extra_details-content__package-details > a:hover, .package__search_result .result__extra_details_wrapper .extra_details-content__package-details > a:active, .package__search_result .result__extra_details_wrapper .extra_details-content__package-details > a:focus {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.package__search_result .result__extra_details_wrapper .extra_details-content__package-details > a:hover {
  text-decoration: underline;
}
.package__search_result .result__extra_details_wrapper .extra_details-content__cancellation {
  width: 70%;
  padding-right: 0px;
  padding-left: 0px;
  cursor: pointer;
  float: left;
}
.package__search_result .result__extra_details_wrapper .extra_details-content__cancellation > a {
  margin-right: 1rem;
  text-transform: uppercase;
  width: 42%;
  color: #087dc2;
}
.package__search_result .result__extra_details_wrapper .extra_details-content__expand-package {
  float: left;
}
.package__search_result .result__extra_details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  background-color: #f0f5f8;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #c7e5f6;
}
.package__search_result .result__extra_details_text {
  float: left;
}
.package__search_result .result__package {
  text-align: center;
  max-height: 100px;
}
.package__search_result .result__package img {
  width: 100%;
  max-height: 70px;
  max-width: 120px;
}
.package__search_result .result__price {
  float: left;
  width: inherit;
}
.package__search_result .result__price_amount {
  font-size: 1.125rem;
  font-family: "Open Sans";
  color: #ec2227;
}
.package__search_result .result__rfq-check {
  padding: 0.325rem;
  text-align: center;
}
.package__search_result .result__status {
  position: absolute;
  width: 18%;
  bottom: 0px;
  text-align: center;
}
.package__search_result .result__package_slideshow_button, .package__search_result .result__extra_details_text, .package__search_result .result__book_button, .package__search_result .result__select_button, .package__search_result .result__select_button-active {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
}
.package__search_result .result__package_slideshow {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-color: #c7e5f6;
}
.package__search_result .result__package_slideshow_button {
  width: 100%;
  background-color: #c7e5f6;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-align: center;
  color: #087dc2;
}
.package__search_result .result__package_slideshow_button:hover, .package__search_result .result__package_slideshow_button:active, .package__search_result .result__package_slideshow_button:focus {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.package__search_result .result__package_slideshow_button:hover {
  text-decoration: underline;
}
.package__search_result .result__book, .package__search_result .result__select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-color: #ccebfd;
}
.package__search_result .result__book_button, .package__search_result .result__book_button-active, .package__search_result .result__select_button, .package__search_result .result__select_button-active {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  background-color: #ec2227;
  border: none;
  line-height: inherit;
  border-radius: 0;
  background-image: none;
  width: 100%;
  color: #fff;
  font-weight: 700;
}
.package__search_result .result__book_button:hover, .package__search_result .result__book_button:focus, .package__search_result .result__book_button:active, .package__search_result .result__book_button-active:hover, .package__search_result .result__book_button-active:focus, .package__search_result .result__book_button-active:active, .package__search_result .result__select_button:hover, .package__search_result .result__select_button:focus, .package__search_result .result__select_button:active, .package__search_result .result__select_button-active:hover, .package__search_result .result__select_button-active:focus, .package__search_result .result__select_button-active:active {
  background-color: #ec2227;
}
.package__search_result .result__select {
  float: right;
  height: 0px;
}
.package__search_result .result__select_button {
  background-color: #087dc2;
}
.package__search_result .result__select_button:hover, .package__search_result .result__select_button:focus, .package__search_result .result__select_button:active {
  background-color: #087dc2;
}
.package__search_result .result__select_button-active {
  color: #087dc2;
  background-color: #c7e5f6;
}
.package__search_result .result__select_button-active:hover, .package__search_result .result__select_button-active:focus, .package__search_result .result__select_button-active:active {
  background-color: #c7e5f6;
}
.package__search_result .select-button__triangle {
  position: relative;
  margin-left: 5px;
  top: 10px;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-top: 7px solid #fff;
}
.package__search_result .select-button__triangle-active {
  position: relative;
  margin-left: 5px;
  top: 10px;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-top: 7px solid #087dc2;
}

.package-cancellation-policy-loader {
  padding: 15px;
}

.package-result-price-status {
  position: absolute;
  bottom: 0px;
  text-align: center;
}
.package-result-price-status__price-changed {
  color: #FF7000;
}
.package-result-price-status__available {
  color: #3c763d;
  margin-left: 4rem;
}
.package-result-price-status__unavailable {
  color: #dd4b39;
}

.result__extra_details_cancellation .cancellation__text {
  float: right;
  margin-right: 10%;
  position: relative;
  padding: 0.3125rem 0;
  width: 30%;
  text-align: center;
  background-color: #c7e5f6;
}
.result__extra_details_cancellation .cancellation__text:hover, .result__extra_details_cancellation .cancellation__text:active, .result__extra_details_cancellation .cancellation__text:focus {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.result__extra_details_cancellation .cancellation__text:hover {
  background-color: #c7e5f6;
  text-decoration: underline;
}

.result-header {
  text-transform: uppercase;
  color: #087dc2;
  border-bottom: 1px solid #aac7d8;
  padding: 0.375rem 0.625rem 0.375rem 0.625rem;
  margin-bottom: 0.375rem;
  margin-right: -0.9375rem;
}
.result-header:before, .result-header:after {
  content: " ";
  display: table;
}
.result-header:after {
  clear: both;
}
.result-header__city {
  float: left;
}
.result-header__dates {
  float: right;
}

.package-rate-details {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.625rem;
}
.package-rate-details__content {
  background-color: #f0f5f8;
  border-left: 10px solid #c7e5f6;
  border-right: 10px solid #c7e5f6;
  border-top: 10px solid #c7e5f6;
  border-bottom: 10px solid #c7e5f6;
}

.package-table {
  background-color: #fff;
  border-collapse: collapse;
  overflow: auto;
  width: 100%;
  table-layout: fixed;
}
.package-table tr {
  padding: 0.3125rem;
  border-bottom: 1px solid #f0f5f8;
}
.package-table tr:last-child {
  border-bottom: 0;
}
.package-table th {
  border-bottom: 0;
  text-transform: uppercase;
  background-color: #f0f5f8;
}
.package-table td,
.package-table th {
  padding-left: 0.3125rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  text-align: left;
}
.package-table td > .result__book_button {
  width: 70%;
  margin-left: 20%;
}
.package-table td > a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  color: #087dc2;
  font-size: 11px;
}
.package-table th > button {
  background: transparent;
  border: none;
  padding-right: 1rem;
  float: right;
}
.package-table td:nth-child(4),
.package-table th:nth-child(4) {
  text-align: right;
}
.package-table td:nth-child(4) {
  padding-left: 3rem;
}
.package-table__package-rate {
  color: #000;
}
.package-table__currency {
  float: left;
}

.package-details-modal .modal__content {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.package-details-modal .modal-dialog {
  width: 80%;
  margin: 30px auto;
}
.package-details-modal .modal-header {
  padding-bottom: 0.3125rem;
  padding-right: 0;
  padding-left: 0;
  border-bottom: 0;
}
.package-details-modal .modal-header:before, .package-details-modal .modal-header:after {
  content: " ";
  display: table;
}
.package-details-modal .modal-header:after {
  clear: both;
}
.package-details-modal .modal-title {
  color: #087dc2;
  padding-bottom: 0.375rem;
}
.package-details-modal .modal-title > button {
  float: right;
  background-color: #fff;
}

.package-details,
.package-hotel-highlights,
.dynamic-package-details {
  height: 500px;
  /*.modal-content {
  	height:500px;
   padding-top: 0px;
   padding-bottom: 0px;
   margin-bottom: 0px;
  	margin-top: 0px;

  }*/
}
.package-details:before, .package-details:after,
.package-hotel-highlights:before,
.package-hotel-highlights:after,
.dynamic-package-details:before,
.dynamic-package-details:after {
  content: " ";
  display: table;
}
.package-details:after,
.package-hotel-highlights:after,
.dynamic-package-details:after {
  clear: both;
}
.package-details .highlights__sidebar,
.package-hotel-highlights .highlights__sidebar,
.dynamic-package-details .highlights__sidebar {
  float: left;
  width: 25%;
  height: 100%;
  background-color: #EEEFF3;
}
.package-details .highlights__hotel-details,
.package-hotel-highlights .highlights__hotel-details,
.dynamic-package-details .highlights__hotel-details {
  float: right;
  width: 75%;
  height: 100%;
  margin-top: 0;
}
.package-details .sidebar__back-button:before, .package-details .sidebar__back-button:after,
.package-hotel-highlights .sidebar__back-button:before,
.package-hotel-highlights .sidebar__back-button:after,
.dynamic-package-details .sidebar__back-button:before,
.dynamic-package-details .sidebar__back-button:after {
  content: " ";
  display: table;
}
.package-details .sidebar__back-button:after,
.package-hotel-highlights .sidebar__back-button:after,
.dynamic-package-details .sidebar__back-button:after {
  clear: both;
}
.package-details .sidebar__back-button .triangle__container,
.package-hotel-highlights .sidebar__back-button .triangle__container,
.dynamic-package-details .sidebar__back-button .triangle__container {
  border-radius: 100%;
  background-color: #ec2227;
  position: relative;
  float: right;
  width: 25px;
  height: 25px;
}
.package-details .sidebar__back-button .triangle,
.package-hotel-highlights .sidebar__back-button .triangle,
.dynamic-package-details .sidebar__back-button .triangle {
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
  position: absolute;
  top: 5px;
  left: 1px;
}
.package-details .sidebar__tabs,
.package-hotel-highlights .sidebar__tabs,
.dynamic-package-details .sidebar__tabs {
  width: 100%;
  height: 80%;
  padding-bottom: 10px;
}
.package-details .sidebar__tabs > li,
.package-hotel-highlights .sidebar__tabs > li,
.dynamic-package-details .sidebar__tabs > li {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  position: relative;
}
.package-details .sidebar__tabs > li > a,
.package-hotel-highlights .sidebar__tabs > li > a,
.dynamic-package-details .sidebar__tabs > li > a {
  color: #087dc2;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
.package-details .sidebar__tabs > li .sidebar__back-button,
.package-hotel-highlights .sidebar__tabs > li .sidebar__back-button,
.dynamic-package-details .sidebar__tabs > li .sidebar__back-button {
  color: #ec2227;
}
.package-details .sidebar__tabs > li .sidebar__back-button:hover,
.package-hotel-highlights .sidebar__tabs > li .sidebar__back-button:hover,
.dynamic-package-details .sidebar__tabs > li .sidebar__back-button:hover {
  color: #fff;
}
.package-details .sidebar__tabs > li:hover,
.package-hotel-highlights .sidebar__tabs > li:hover,
.dynamic-package-details .sidebar__tabs > li:hover {
  background-color: #087dc2;
}
.package-details .sidebar__tabs > li:hover a,
.package-hotel-highlights .sidebar__tabs > li:hover a,
.dynamic-package-details .sidebar__tabs > li:hover a {
  color: #fff;
}
.package-details .sidebar__tabs > li:after,
.package-hotel-highlights .sidebar__tabs > li:after,
.dynamic-package-details .sidebar__tabs > li:after {
  content: "";
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: white;
  position: absolute;
  top: 100%;
}
.package-details .sidebar__tabs .active,
.package-hotel-highlights .sidebar__tabs .active,
.dynamic-package-details .sidebar__tabs .active {
  background-color: #fff;
}
.package-details .sidebar__tabs .active a,
.package-hotel-highlights .sidebar__tabs .active a,
.dynamic-package-details .sidebar__tabs .active a {
  color: #ec2227;
}
.package-details .modal__content,
.package-hotel-highlights .modal__content,
.dynamic-package-details .modal__content {
  height: 500px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.package-details .modal__content_loader,
.package-hotel-highlights .modal__content_loader,
.dynamic-package-details .modal__content_loader {
  padding: 15px;
}
.package-details__header,
.package-hotel-highlights__header,
.dynamic-package-details__header {
  color: #087dc2;
  text-transform: uppercase;
  background-color: #c7e5f6;
  padding: 0.625rem;
}
.package-details__header > span,
.package-hotel-highlights__header > span,
.dynamic-package-details__header > span {
  float: right;
  text-transform: capitalize;
}
.package-details .package-detail__sidebar,
.package-details .package-hotel-highlights__sidebar,
.package-details .dynamic-package-detail__sidebar,
.package-hotel-highlights .package-detail__sidebar,
.package-hotel-highlights .package-hotel-highlights__sidebar,
.package-hotel-highlights .dynamic-package-detail__sidebar,
.dynamic-package-details .package-detail__sidebar,
.dynamic-package-details .package-hotel-highlights__sidebar,
.dynamic-package-details .dynamic-package-detail__sidebar {
  float: left;
  width: 25%;
  height: 100%;
  background-color: #EEEFF3;
}
.package-details .package-detail__content,
.package-details .package-hotel-highlights__content,
.package-details .dynamic-package-detail__content,
.package-hotel-highlights .package-detail__content,
.package-hotel-highlights .package-hotel-highlights__content,
.package-hotel-highlights .dynamic-package-detail__content,
.dynamic-package-details .package-detail__content,
.dynamic-package-details .package-hotel-highlights__content,
.dynamic-package-details .dynamic-package-detail__content {
  float: right;
  width: 75%;
  height: 100%;
  overflow: auto;
  margin-top: 0;
  padding: 0.3125rem;
}
.package-details .package-overview__header,
.package-details .package-incl-excl__header,
.package-details .package-itinerary__header,
.package-details .package-hotel-description__header,
.package-details .package-canc-policy__header,
.package-hotel-highlights .package-overview__header,
.package-hotel-highlights .package-incl-excl__header,
.package-hotel-highlights .package-itinerary__header,
.package-hotel-highlights .package-hotel-description__header,
.package-hotel-highlights .package-canc-policy__header,
.dynamic-package-details .package-overview__header,
.dynamic-package-details .package-incl-excl__header,
.dynamic-package-details .package-itinerary__header,
.dynamic-package-details .package-hotel-description__header,
.dynamic-package-details .package-canc-policy__header {
  min-height: 30px;
  background-color: #f0f5f8;
  color: #838383;
  text-transform: uppercase;
  padding: 5px;
}
.package-details .package-overview__content,
.package-details .package-incl-excl__content,
.package-details .package-itinerary__content,
.package-details .package-hotel-description__content,
.package-details .package-canc-policy__content,
.package-hotel-highlights .package-overview__content,
.package-hotel-highlights .package-incl-excl__content,
.package-hotel-highlights .package-itinerary__content,
.package-hotel-highlights .package-hotel-description__content,
.package-hotel-highlights .package-canc-policy__content,
.dynamic-package-details .package-overview__content,
.dynamic-package-details .package-incl-excl__content,
.dynamic-package-details .package-itinerary__content,
.dynamic-package-details .package-hotel-description__content,
.dynamic-package-details .package-canc-policy__content {
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 5px;
}
.package-details .package-overview__content_remarks,
.package-details .package-incl-excl__content_remarks,
.package-details .package-itinerary__content_remarks,
.package-details .package-hotel-description__content_remarks,
.package-details .package-canc-policy__content_remarks,
.package-hotel-highlights .package-overview__content_remarks,
.package-hotel-highlights .package-incl-excl__content_remarks,
.package-hotel-highlights .package-itinerary__content_remarks,
.package-hotel-highlights .package-hotel-description__content_remarks,
.package-hotel-highlights .package-canc-policy__content_remarks,
.dynamic-package-details .package-overview__content_remarks,
.dynamic-package-details .package-incl-excl__content_remarks,
.dynamic-package-details .package-itinerary__content_remarks,
.dynamic-package-details .package-hotel-description__content_remarks,
.dynamic-package-details .package-canc-policy__content_remarks {
  font-weight: 900;
}
.package-details .package-overview__content .glyphicon,
.package-details .package-incl-excl__content .glyphicon,
.package-details .package-itinerary__content .glyphicon,
.package-details .package-hotel-description__content .glyphicon,
.package-details .package-canc-policy__content .glyphicon,
.package-hotel-highlights .package-overview__content .glyphicon,
.package-hotel-highlights .package-incl-excl__content .glyphicon,
.package-hotel-highlights .package-itinerary__content .glyphicon,
.package-hotel-highlights .package-hotel-description__content .glyphicon,
.package-hotel-highlights .package-canc-policy__content .glyphicon,
.dynamic-package-details .package-overview__content .glyphicon,
.dynamic-package-details .package-incl-excl__content .glyphicon,
.dynamic-package-details .package-itinerary__content .glyphicon,
.dynamic-package-details .package-hotel-description__content .glyphicon,
.dynamic-package-details .package-canc-policy__content .glyphicon {
  color: #F3C103;
}
.package-details .package-overview__amenity,
.package-details .package-incl-excl__amenity,
.package-details .package-itinerary__amenity,
.package-details .package-hotel-description__amenity,
.package-details .package-canc-policy__amenity,
.package-hotel-highlights .package-overview__amenity,
.package-hotel-highlights .package-incl-excl__amenity,
.package-hotel-highlights .package-itinerary__amenity,
.package-hotel-highlights .package-hotel-description__amenity,
.package-hotel-highlights .package-canc-policy__amenity,
.dynamic-package-details .package-overview__amenity,
.dynamic-package-details .package-incl-excl__amenity,
.dynamic-package-details .package-itinerary__amenity,
.dynamic-package-details .package-hotel-description__amenity,
.dynamic-package-details .package-canc-policy__amenity {
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 1px;
}
.package-details .package-rate,
.package-details .alternative-hotel,
.package-hotel-highlights .package-rate,
.package-hotel-highlights .alternative-hotel,
.dynamic-package-details .package-rate,
.dynamic-package-details .alternative-hotel {
  font-size: 1.125rem;
}
.package-details .package-rate_main,
.package-details .alternative-hotel_main,
.package-hotel-highlights .package-rate_main,
.package-hotel-highlights .alternative-hotel_main,
.dynamic-package-details .package-rate_main,
.dynamic-package-details .alternative-hotel_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.package-details .package-rate_main:before, .package-details .package-rate_main:after,
.package-details .alternative-hotel_main:before,
.package-details .alternative-hotel_main:after,
.package-hotel-highlights .package-rate_main:before,
.package-hotel-highlights .package-rate_main:after,
.package-hotel-highlights .alternative-hotel_main:before,
.package-hotel-highlights .alternative-hotel_main:after,
.dynamic-package-details .package-rate_main:before,
.dynamic-package-details .package-rate_main:after,
.dynamic-package-details .alternative-hotel_main:before,
.dynamic-package-details .alternative-hotel_main:after {
  content: " ";
  display: table;
}
.package-details .package-rate_main:after,
.package-details .alternative-hotel_main:after,
.package-hotel-highlights .package-rate_main:after,
.package-hotel-highlights .alternative-hotel_main:after,
.dynamic-package-details .package-rate_main:after,
.dynamic-package-details .alternative-hotel_main:after {
  clear: both;
}
.package-details .package-rate_main .content,
.package-details .alternative-hotel_main .content,
.package-hotel-highlights .package-rate_main .content,
.package-hotel-highlights .alternative-hotel_main .content,
.dynamic-package-details .package-rate_main .content,
.dynamic-package-details .alternative-hotel_main .content {
  padding-left: 0;
}
.package-details .package-rate_wrapper,
.package-details .alternative-hotel_wrapper,
.package-hotel-highlights .package-rate_wrapper,
.package-hotel-highlights .alternative-hotel_wrapper,
.dynamic-package-details .package-rate_wrapper,
.dynamic-package-details .alternative-hotel_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .package-details .package-rate_wrapper,
.package-details .alternative-hotel_wrapper,
.package-hotel-highlights .package-rate_wrapper,
.package-hotel-highlights .alternative-hotel_wrapper,
.dynamic-package-details .package-rate_wrapper,
.dynamic-package-details .alternative-hotel_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .package-details .package-rate_wrapper,
.package-details .alternative-hotel_wrapper,
.package-hotel-highlights .package-rate_wrapper,
.package-hotel-highlights .alternative-hotel_wrapper,
.dynamic-package-details .package-rate_wrapper,
.dynamic-package-details .alternative-hotel_wrapper {
    max-width: 1400px;
  }
}
.package-details .package-rate_wrapper .single-content,
.package-details .alternative-hotel_wrapper .single-content,
.package-hotel-highlights .package-rate_wrapper .single-content,
.package-hotel-highlights .alternative-hotel_wrapper .single-content,
.dynamic-package-details .package-rate_wrapper .single-content,
.dynamic-package-details .alternative-hotel_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.package-details .package-rate_row,
.package-details .alternative-hotel_row,
.package-hotel-highlights .package-rate_row,
.package-hotel-highlights .alternative-hotel_row,
.dynamic-package-details .package-rate_row,
.dynamic-package-details .alternative-hotel_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.package-details .package-rate_row:before, .package-details .package-rate_row:after,
.package-details .alternative-hotel_row:before,
.package-details .alternative-hotel_row:after,
.package-hotel-highlights .package-rate_row:before,
.package-hotel-highlights .package-rate_row:after,
.package-hotel-highlights .alternative-hotel_row:before,
.package-hotel-highlights .alternative-hotel_row:after,
.dynamic-package-details .package-rate_row:before,
.dynamic-package-details .package-rate_row:after,
.dynamic-package-details .alternative-hotel_row:before,
.dynamic-package-details .alternative-hotel_row:after {
  content: " ";
  display: table;
}
.package-details .package-rate_row:after,
.package-details .alternative-hotel_row:after,
.package-hotel-highlights .package-rate_row:after,
.package-hotel-highlights .alternative-hotel_row:after,
.dynamic-package-details .package-rate_row:after,
.dynamic-package-details .alternative-hotel_row:after {
  clear: both;
}
.package-details .package-rate_card,
.package-details .alternative-hotel_card,
.package-hotel-highlights .package-rate_card,
.package-hotel-highlights .alternative-hotel_card,
.dynamic-package-details .package-rate_card,
.dynamic-package-details .alternative-hotel_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.package-details .package-rate_card:before, .package-details .package-rate_card:after,
.package-details .alternative-hotel_card:before,
.package-details .alternative-hotel_card:after,
.package-hotel-highlights .package-rate_card:before,
.package-hotel-highlights .package-rate_card:after,
.package-hotel-highlights .alternative-hotel_card:before,
.package-hotel-highlights .alternative-hotel_card:after,
.dynamic-package-details .package-rate_card:before,
.dynamic-package-details .package-rate_card:after,
.dynamic-package-details .alternative-hotel_card:before,
.dynamic-package-details .alternative-hotel_card:after {
  content: " ";
  display: table;
}
.package-details .package-rate_card:after,
.package-details .alternative-hotel_card:after,
.package-hotel-highlights .package-rate_card:after,
.package-hotel-highlights .alternative-hotel_card:after,
.dynamic-package-details .package-rate_card:after,
.dynamic-package-details .alternative-hotel_card:after {
  clear: both;
}
.package-details .package-rate_footer,
.package-details .alternative-hotel_footer,
.package-hotel-highlights .package-rate_footer,
.package-hotel-highlights .alternative-hotel_footer,
.dynamic-package-details .package-rate_footer,
.dynamic-package-details .alternative-hotel_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.package-details .package-rate_footer:before, .package-details .package-rate_footer:after,
.package-details .alternative-hotel_footer:before,
.package-details .alternative-hotel_footer:after,
.package-hotel-highlights .package-rate_footer:before,
.package-hotel-highlights .package-rate_footer:after,
.package-hotel-highlights .alternative-hotel_footer:before,
.package-hotel-highlights .alternative-hotel_footer:after,
.dynamic-package-details .package-rate_footer:before,
.dynamic-package-details .package-rate_footer:after,
.dynamic-package-details .alternative-hotel_footer:before,
.dynamic-package-details .alternative-hotel_footer:after {
  content: " ";
  display: table;
}
.package-details .package-rate_footer:after,
.package-details .alternative-hotel_footer:after,
.package-hotel-highlights .package-rate_footer:after,
.package-hotel-highlights .alternative-hotel_footer:after,
.dynamic-package-details .package-rate_footer:after,
.dynamic-package-details .alternative-hotel_footer:after {
  clear: both;
}
.package-details .package-rate:before, .package-details .package-rate:after,
.package-details .alternative-hotel:before,
.package-details .alternative-hotel:after,
.package-hotel-highlights .package-rate:before,
.package-hotel-highlights .package-rate:after,
.package-hotel-highlights .alternative-hotel:before,
.package-hotel-highlights .alternative-hotel:after,
.dynamic-package-details .package-rate:before,
.dynamic-package-details .package-rate:after,
.dynamic-package-details .alternative-hotel:before,
.dynamic-package-details .alternative-hotel:after {
  content: " ";
  display: table;
}
.package-details .package-rate:after,
.package-details .alternative-hotel:after,
.package-hotel-highlights .package-rate:after,
.package-hotel-highlights .alternative-hotel:after,
.dynamic-package-details .package-rate:after,
.dynamic-package-details .alternative-hotel:after {
  clear: both;
}
.package-details .package-rate_card,
.package-details .alternative-hotel_card,
.package-hotel-highlights .package-rate_card,
.package-hotel-highlights .alternative-hotel_card,
.dynamic-package-details .package-rate_card,
.dynamic-package-details .alternative-hotel_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.package-details .package-rate_footer,
.package-details .alternative-hotel_footer,
.package-hotel-highlights .package-rate_footer,
.package-hotel-highlights .alternative-hotel_footer,
.dynamic-package-details .package-rate_footer,
.dynamic-package-details .alternative-hotel_footer {
  margin: 0;
  background-color: #f0f5f8;
}
.package-details .package-rate_footer .package-rate_details,
.package-details .alternative-hotel_footer .package-rate_details,
.package-hotel-highlights .package-rate_footer .package-rate_details,
.package-hotel-highlights .alternative-hotel_footer .package-rate_details,
.dynamic-package-details .package-rate_footer .package-rate_details,
.dynamic-package-details .alternative-hotel_footer .package-rate_details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  background-color: #c7e5f6;
  padding: 0.3125rem;
}
.package-details .package-rate__category,
.package-details .alternative-hotel__category,
.package-hotel-highlights .package-rate__category,
.package-hotel-highlights .alternative-hotel__category,
.dynamic-package-details .package-rate__category,
.dynamic-package-details .alternative-hotel__category {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .package-rate__city,
.package-details .alternative-hotel__city,
.package-hotel-highlights .package-rate__city,
.package-hotel-highlights .alternative-hotel__city,
.dynamic-package-details .package-rate__city,
.dynamic-package-details .alternative-hotel__city {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .package-rate__star-rating,
.package-details .alternative-hotel__star-rating,
.package-hotel-highlights .package-rate__star-rating,
.package-hotel-highlights .alternative-hotel__star-rating,
.dynamic-package-details .package-rate__star-rating,
.dynamic-package-details .alternative-hotel__star-rating {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
}
.package-details .package-rate__star-rating .glyphicon,
.package-details .alternative-hotel__star-rating .glyphicon,
.package-hotel-highlights .package-rate__star-rating .glyphicon,
.package-hotel-highlights .alternative-hotel__star-rating .glyphicon,
.dynamic-package-details .package-rate__star-rating .glyphicon,
.dynamic-package-details .alternative-hotel__star-rating .glyphicon {
  color: #F3C103;
}
.package-details .package-rate__total-price,
.package-details .alternative-hotel__total-price,
.package-hotel-highlights .package-rate__total-price,
.package-hotel-highlights .alternative-hotel__total-price,
.dynamic-package-details .package-rate__total-price,
.dynamic-package-details .alternative-hotel__total-price {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  color: #ec2227;
}
.package-details .package-rate__currency,
.package-details .alternative-hotel__currency,
.package-hotel-highlights .package-rate__currency,
.package-hotel-highlights .alternative-hotel__currency,
.dynamic-package-details .package-rate__currency,
.dynamic-package-details .alternative-hotel__currency {
  color: #838383;
}
.package-details .package-rate__book-button,
.package-details .alternative-hotel__book-button,
.package-hotel-highlights .package-rate__book-button,
.package-hotel-highlights .alternative-hotel__book-button,
.dynamic-package-details .package-rate__book-button,
.dynamic-package-details .alternative-hotel__book-button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  background-color: #ec2227;
  color: #fff;
  padding: 0.3125rem;
}
.package-details .rate-products,
.package-hotel-highlights .rate-products,
.dynamic-package-details .rate-products {
  border: 5px solid #c7e5f6;
  padding: 0.3125rem;
}
.package-details .rate-products__header,
.package-hotel-highlights .rate-products__header,
.dynamic-package-details .rate-products__header {
  border-bottom: 1px solid #c7e5f6;
  text-transform: uppercase;
  background-color: #fff;
  color: #087dc2;
  font-weight: 400;
}
.package-details .rate-products__header:before, .package-details .rate-products__header:after,
.package-hotel-highlights .rate-products__header:before,
.package-hotel-highlights .rate-products__header:after,
.dynamic-package-details .rate-products__header:before,
.dynamic-package-details .rate-products__header:after {
  content: " ";
  display: table;
}
.package-details .rate-products__header:after,
.package-hotel-highlights .rate-products__header:after,
.dynamic-package-details .rate-products__header:after {
  clear: both;
}
.package-details .rate-products__header > h4,
.package-hotel-highlights .rate-products__header > h4,
.dynamic-package-details .rate-products__header > h4 {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .rate-products__content .content-hotels,
.package-hotel-highlights .rate-products__content .content-hotels,
.dynamic-package-details .rate-products__content .content-hotels {
  width: 100%;
  border-bottom: 1px solid #f0f5f8;
  background-color: #fff;
  font-weight: 400;
  padding-top: 0.625rem;
}
.package-details .rate-products__content .content-hotels:before, .package-details .rate-products__content .content-hotels:after,
.package-hotel-highlights .rate-products__content .content-hotels:before,
.package-hotel-highlights .rate-products__content .content-hotels:after,
.dynamic-package-details .rate-products__content .content-hotels:before,
.dynamic-package-details .rate-products__content .content-hotels:after {
  content: " ";
  display: table;
}
.package-details .rate-products__content .content-hotels:after,
.package-hotel-highlights .rate-products__content .content-hotels:after,
.dynamic-package-details .rate-products__content .content-hotels:after {
  clear: both;
}
.package-details .rate-products__content .content-hotels__details-row:before, .package-details .rate-products__content .content-hotels__details-row:after,
.package-hotel-highlights .rate-products__content .content-hotels__details-row:before,
.package-hotel-highlights .rate-products__content .content-hotels__details-row:after,
.dynamic-package-details .rate-products__content .content-hotels__details-row:before,
.dynamic-package-details .rate-products__content .content-hotels__details-row:after {
  content: " ";
  display: table;
}
.package-details .rate-products__content .content-hotels__details-row:after,
.package-hotel-highlights .rate-products__content .content-hotels__details-row:after,
.dynamic-package-details .rate-products__content .content-hotels__details-row:after {
  clear: both;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__name, .package-details .rate-products__content .content-hotels__details-row .hotel__city, .package-details .rate-products__content .content-hotels__details-row .hotel__roomtype, .package-details .rate-products__content .content-hotels__details-row .hotel__star-rating, .package-details .rate-products__content .content-hotels__details-row .hotel__no-days,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__name,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__city,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__roomtype,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__star-rating,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__no-days,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__name,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__city,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__roomtype,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__star-rating,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__no-days {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__city,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__city,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__city {
  text-transform: uppercase;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__star-rating,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__star-rating,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__star-rating {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__star-rating .glyphicon,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__star-rating .glyphicon,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__star-rating .glyphicon {
  color: #F3C103;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__highlights,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights {
  width: 100%;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__highlights:before, .package-details .rate-products__content .content-hotels__details-row .hotel__highlights:after,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights:before,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights:after,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights:before,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights:after {
  content: " ";
  display: table;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__highlights:after,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights:after,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights:after {
  clear: both;
}
.package-details .rate-products__content .content-hotels__details-row .hotel__highlights > button,
.package-hotel-highlights .rate-products__content .content-hotels__details-row .hotel__highlights > button,
.dynamic-package-details .rate-products__content .content-hotels__details-row .hotel__highlights > button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  color: #087dc2;
  background-color: #c7e5f6;
}

.package__pax-info_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.package__pax-info_main:before, .package__pax-info_main:after {
  content: " ";
  display: table;
}
.package__pax-info_main:after {
  clear: both;
}
.package__pax-info_main .content {
  padding-left: 0;
}
.package__pax-info_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .package__pax-info_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .package__pax-info_wrapper {
    max-width: 1400px;
  }
}
.package__pax-info_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.package__pax-info_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.package__pax-info_card:before, .package__pax-info_card:after {
  content: " ";
  display: table;
}
.package__pax-info_card:after {
  clear: both;
}
.package__pax-info_card_header {
  padding: 0.375rem 0.9375rem;
}
.package__pax-info_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.package__pax-info_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.package__pax-info_header ul:before, .package__pax-info_header ul:after {
  content: " ";
  display: table;
}
.package__pax-info_header ul:after {
  clear: both;
}
.package__pax-info_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.package__pax-info_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.package__pax-info_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.package__pax-info_card_content {
  margin-bottom: 0.9375rem;
}
.package__pax-info_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.package__pax-info_card_header {
  text-transform: uppercase;
  color: #087dc2;
}
.package__pax-info_card_info {
  color: #ec2227;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding-left: 0.2rem;
}
.package__pax-info_row:before, .package__pax-info_row:after {
  content: " ";
  display: table;
}
.package__pax-info_row:after {
  clear: both;
}
.package__pax-info_header ul > li {
  float: left;
  width: 20%;
  text-align: center;
  font-size: 0.625rem;
}
.package__pax-info_header ul > li:nth-child(1), .package__pax-info_header ul > li:nth-child(2) {
  width: 10%;
  text-align: center;
}
.package__pax-info_header ul > li:last-child {
  width: 20%;
  text-align: center;
}
.package__pax-info_footer {
  border-top: 1px solid #087dc2;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.package__pax-info_footer:before, .package__pax-info_footer:after {
  content: " ";
  display: table;
}
.package__pax-info_footer:after {
  clear: both;
}
.package__pax-info .footer_text {
  margin-bottom: 0;
}
.package__pax-info_room {
  margin-bottom: 0.9375rem;
  border-top: 1px solid #087dc2;
}
.package__pax-info_room_text {
  text-transform: uppercase;
  color: #087dc2;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}
.package__pax-info_room .pax-info {
  padding-bottom: 0;
}
.package__pax-info_button {
  padding: 0.3125rem 0;
  font-size: 0.875rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  border: none;
  width: 25%;
  font-weight: 700;
  float: right;
}
.package__pax-info_button:focus, .package__pax-info_button.focus, .package__pax-info_button:active:focus, .package__pax-info_button:active.focus, .package__pax-info_button.active:focus, .package__pax-info_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.package__pax-info_button:hover, .package__pax-info_button:focus, .package__pax-info_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.package__pax-info_button:active, .package__pax-info_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.package__pax-info_button.disabled, .package__pax-info_button[disabled], fieldset[disabled] .package__pax-info_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.package__pax-info_save-traveler {
  width: 100%;
}
.package__pax-info_save-traveler:before, .package__pax-info_save-traveler:after {
  content: " ";
  display: table;
}
.package__pax-info_save-traveler:after {
  clear: both;
}
.package__pax-info_save-traveler_button {
  float: right;
  width: 25%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #fff;
  background-color: #087dc2;
  font-weight: 700;
  margin-bottom: 0.625rem;
}
.package__pax-info_save-traveler_button:focus, .package__pax-info_save-traveler_button.focus, .package__pax-info_save-traveler_button:active:focus, .package__pax-info_save-traveler_button:active.focus, .package__pax-info_save-traveler_button.active:focus, .package__pax-info_save-traveler_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.package__pax-info_save-traveler_button:hover, .package__pax-info_save-traveler_button:focus, .package__pax-info_save-traveler_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #043e60;
}
.package__pax-info_save-traveler_button:active, .package__pax-info_save-traveler_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.package__pax-info_save-traveler_button.disabled, .package__pax-info_save-traveler_button[disabled], fieldset[disabled] .package__pax-info_save-traveler_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.package__pax-info_footer-row {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.package__pax-info_footer-row:before, .package__pax-info_footer-row:after {
  content: " ";
  display: table;
}
.package__pax-info_footer-row:after {
  clear: both;
}

.pax-info__search-form-container {
  background-image: url(../../../images/hero-image-3.jpg);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-size: cover;
  background-position: center left;
  min-height: 100vh;
}
.pax-info__search-form-container_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.pax-info__search-form-container_main:before, .pax-info__search-form-container_main:after {
  content: " ";
  display: table;
}
.pax-info__search-form-container_main:after {
  clear: both;
}
.pax-info__search-form-container_main .content {
  padding-left: 0;
}
.pax-info__search-form-container_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .pax-info__search-form-container_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .pax-info__search-form-container_wrapper {
    max-width: 1400px;
  }
}
.pax-info__search-form-container_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.pax-info__search-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-wrapper:before, .pax-info__search-wrapper:after {
  content: " ";
  display: table;
}
.pax-info__search-wrapper:after {
  clear: both;
}
.pax-info__search-form {
  background-color: #fff;
  padding: 1.875rem;
  margin: 0 auto;
  min-height: 300px;
}
.pax-info__search-form_header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.pax-info__page-header {
  color: #fff;
  padding-bottom: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-top: 0;
}
.pax-info__search-trip-type, .pax-info__search-location, .pax-info__search-date, .pax-info__search-traveller, .pax-info__search-multicity, .pax-info__search-nationality, .pax-info__search-residence-country, .pax-info__search-currency, .pax-info__additioal-option, .pax-info__pnr-search-location, .pax-info__product-selection, .pax-info__advance-search, .pax-info__advance-search-params, .pax-info__search-supplier, .pax-info__search-modify-journey {
  margin-bottom: 1rem;
}
.pax-info__with-baggage_label {
  padding: 0.3125rem;
}
.pax-info__pnr-fields {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__pnr-fields:before, .pax-info__pnr-fields:after {
  content: " ";
  display: table;
}
.pax-info__pnr-fields:after {
  clear: both;
}
.pax-info__pnr-search-location .input-container {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  margin-bottom: 1rem;
}
.pax-info__search-trip-type, .pax-info__search-modify-journey {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.pax-info__search-trip-type:before, .pax-info__search-trip-type:after, .pax-info__search-modify-journey:before, .pax-info__search-modify-journey:after {
  content: " ";
  display: table;
}
.pax-info__search-trip-type:after, .pax-info__search-modify-journey:after {
  clear: both;
}
.pax-info__search-trip-type .radio-inline, .pax-info__search-modify-journey .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: auto;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .pax-info__search-trip-type .radio-inline, .pax-info__search-modify-journey .radio-inline {
    width: 16.6666666667%;
  }
}
.pax-info__search-trip-type .radio-inline input[disabled], .pax-info__search-modify-journey .radio-inline input[disabled] {
  cursor: not-allowed;
}
.pax-info__search-modify-journey .modify-journey {
  float: right;
  padding-right: 0.8rem;
}
.pax-info__search-modify-journey .modify-journey__button {
  background: transparent;
  font-weight: 600;
}
.pax-info__search-location {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-location:before, .pax-info__search-location:after {
  content: " ";
  display: table;
}
.pax-info__search-location:after {
  clear: both;
}
.pax-info__search-location .search-location_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.pax-info__search-location .search-location_search-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.pax-info__search-date, .pax-info__hub-surcharge, .pax-info__search-date_hotel-dates {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-date:before, .pax-info__search-date:after, .pax-info__hub-surcharge:before, .pax-info__hub-surcharge:after, .pax-info__search-date_hotel-dates:before, .pax-info__search-date_hotel-dates:after {
  content: " ";
  display: table;
}
.pax-info__search-date:after, .pax-info__hub-surcharge:after, .pax-info__search-date_hotel-dates:after {
  clear: both;
}
.pax-info__search-date .search-date_select, .pax-info__search-date_baggage-one-way, .pax-info__hub-surcharge .search-date_select, .pax-info__hub-surcharge_baggage-one-way, .pax-info__search-date_hotel-dates .search-date_select, .pax-info__search-date_hotel-dates_baggage-one-way {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.pax-info__search-date_baggage-one-way .baggage-label, .pax-info__hub-surcharge_baggage-one-way .baggage-label, .pax-info__search-date_hotel-dates_baggage-one-way .baggage-label {
  margin-top: 1.75rem;
  padding: 0.3125rem;
}
.pax-info__search-date_baggage-round-multicity .baggage-label, .pax-info__hub-surcharge_baggage-round-multicity .baggage-label, .pax-info__search-date_hotel-dates_baggage-round-multicity .baggage-label {
  padding: 0.3125rem;
}
.pax-info__search-date_hotel-dates .search-date_select, .pax-info__hub-surcharge_hotel-dates .search-date_select, .pax-info__search-date_hotel-dates_hotel-dates .search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  margin-right: -13px;
  width: 35%;
}
.pax-info__search-date .date-field-with-duration, .pax-info__hub-surcharge .date-field-with-duration, .pax-info__search-date_hotel-dates .date-field-with-duration {
  width: 40%;
}
.pax-info__search-date-verticle {
  margin-left: -15px;
  margin-right: -15px;
  width: 50%;
  float: left;
  padding-right: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}
.pax-info__search-date-verticle:before, .pax-info__search-date-verticle:after {
  content: " ";
  display: table;
}
.pax-info__search-date-verticle:after {
  clear: both;
}
.pax-info__search-date-verticle .date-range-picker__from-date, .pax-info__search-date-verticle .date-range-picker__to-date {
  margin-bottom: 1rem;
}
.pax-info__hub-surcharge-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.pax-info__hub-surcharge-container:before, .pax-info__hub-surcharge-container:after {
  content: " ";
  display: table;
}
.pax-info__hub-surcharge-container:after {
  clear: both;
}
.pax-info__search-nationality, .pax-info__search-residence-country {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-nationality:before, .pax-info__search-nationality:after, .pax-info__search-residence-country:before, .pax-info__search-residence-country:after {
  content: " ";
  display: table;
}
.pax-info__search-nationality:after, .pax-info__search-residence-country:after {
  clear: both;
}
.pax-info__search-currency {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-currency:before, .pax-info__search-currency:after {
  content: " ";
  display: table;
}
.pax-info__search-currency:after {
  clear: both;
}
.pax-info__air-select-nationality {
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
}
.pax-info__search-multicity-row, .pax-info__search-multicity_hotel-dates, .pax-info__search-multicity_hotel-search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-multicity-row:before, .pax-info__search-multicity-row:after, .pax-info__search-multicity_hotel-dates:before, .pax-info__search-multicity_hotel-dates:after, .pax-info__search-multicity_hotel-search-date:before, .pax-info__search-multicity_hotel-search-date:after {
  content: " ";
  display: table;
}
.pax-info__search-multicity-row:after, .pax-info__search-multicity_hotel-dates:after, .pax-info__search-multicity_hotel-search-date:after {
  clear: both;
}
.pax-info__search-multicity_hotel-dates .hotel-dates__room-info_extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 7px;
  width: 75%;
}
.pax-info__search-multicity_hotel-dates .hotel-dates__search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-multicity_location {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  width: 70%;
}
.pax-info__search-multicity_location .multicity_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0px;
}
.pax-info__search-multicity_location .multicity_autosuggestion .search-location_autosuggestion .itemcontainer {
  left: 0px;
  right: 0px;
}
.pax-info__search-multicity_location-segmentwise-cabin-class {
  width: 50%;
  padding-right: 0rem;
}
.pax-info__search-multicity_location-segmentwise-modify {
  width: 60%;
  padding-right: 0rem;
}
.pax-info__search-multicity_date {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  width: 28%;
}
.pax-info__search-multicity_date .search-date_select {
  width: 100%;
}
.pax-info__search-multicity_date-segmentwise-cabin-class {
  padding-left: 0rem;
}
.pax-info__search-multicity_cabin-class {
  float: left;
  width: 20%;
}
.pax-info__search-multicity_flight-close {
  float: right;
  color: #838383;
  padding-top: 32px;
  cursor: pointer;
}
.pax-info__search-multicity_modify {
  float: left;
  width: 10%;
  display: block;
  padding-top: 2rem;
  font-size: 0.875rem;
}
.pax-info__search-multicity_modify button {
  font-weight: 600;
  background: transparent;
  color: #ec2227;
  padding: 0;
}
.pax-info__search-multicity_modify button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.pax-info__search-traveller {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-traveller:before, .pax-info__search-traveller:after {
  content: " ";
  display: table;
}
.pax-info__search-traveller:after {
  clear: both;
}
.pax-info__search-traveller:before, .pax-info__search-traveller:after {
  content: " ";
  display: table;
}
.pax-info__search-traveller:after {
  clear: both;
}
.pax-info__search-traveller_adult, .pax-info__search-traveller_child, .pax-info__search-traveller_infant, .pax-info__search-traveller_class {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.pax-info__search-traveller_adult input, .pax-info__search-traveller_child input, .pax-info__search-traveller_infant input, .pax-info__search-traveller_class input {
  text-align: center;
}
.pax-info__search-traveller_round-trip-class {
  width: 50%;
  display: table;
}
.pax-info__search-traveller_round-trip-class .round-trip-class__cabin-class {
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
  width: 100%;
}
.pax-info__search-traveller_luggage {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
}
.pax-info__advance-search_title {
  background-color: #f3f9fd;
  padding: 0.5rem 0.5rem 0rem;
  cursor: pointer;
}
.pax-info__advance-search-params {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__advance-search-params:before, .pax-info__advance-search-params:after {
  content: " ";
  display: table;
}
.pax-info__advance-search-params:after {
  clear: both;
}
.pax-info__advance-search-params_stops, .pax-info__advance-search-params_refundable {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.pax-info__search-supplier {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 1rem;
}
.pax-info__search-supplier:before, .pax-info__search-supplier:after {
  content: " ";
  display: table;
}
.pax-info__search-supplier:after {
  clear: both;
}
.pax-info__supplier-selection label {
  padding-right: 2rem;
}
.pax-info__additioal-option {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__additioal-option:before, .pax-info__additioal-option:after {
  content: " ";
  display: table;
}
.pax-info__additioal-option:after {
  clear: both;
}
.pax-info__additioal-option_content {
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 5px;
}
.pax-info__search-submit {
  margin-left: -15px;
  margin-right: -15px;
}
.pax-info__search-submit:before, .pax-info__search-submit:after {
  content: " ";
  display: table;
}
.pax-info__search-submit:after {
  clear: both;
}
.pax-info__search-submit_button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.pax-info__search-submit_button .submit_button {
  padding: 0.375rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
}
.pax-info__search-submit_button .submit_button:focus, .pax-info__search-submit_button .submit_button.focus, .pax-info__search-submit_button .submit_button:active:focus, .pax-info__search-submit_button .submit_button:active.focus, .pax-info__search-submit_button .submit_button.active:focus, .pax-info__search-submit_button .submit_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.pax-info__search-submit_button .submit_button:hover, .pax-info__search-submit_button .submit_button:focus, .pax-info__search-submit_button .submit_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.pax-info__search-submit_button .submit_button:active, .pax-info__search-submit_button .submit_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.pax-info__search-submit_button .submit_button.disabled, .pax-info__search-submit_button .submit_button[disabled], fieldset[disabled] .pax-info__search-submit_button .submit_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.pax-info__search-form_container input[type=text], .pax-info__search-form_container input[type=number],
.pax-info__search-form_container select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.pax-info__search-form_container input[type=text], .pax-info__search-form_container input[type=number] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.pax-info__search-form_container .number-stepper {
  max-width: 250px;
}
.pax-info__search-form_container .number-stepper input[type=text] {
  text-align: center;
}
.pax-info__search-form_container .number-stepper button[disabled] {
  cursor: not-allowed;
  color: #818a91;
}
.pax-info__search-form_container .number-stepper button[disabled]:hover {
  background-color: #818a91;
  color: #fff;
}
.pax-info__search-form_container label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.pax-info__advertise {
  padding: 1.875rem;
  background-color: #fff;
  margin-top: 2.375rem;
  min-height: 300px;
}
.pax-info__advertise p {
  margin-bottom: 0;
}
.pax-info__search-time, .pax-info__search-date, .pax-info__input-field {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.pax-info__search-time:before, .pax-info__search-time:after, .pax-info__search-date:before, .pax-info__search-date:after, .pax-info__input-field:before, .pax-info__input-field:after {
  content: " ";
  display: table;
}
.pax-info__search-time:after, .pax-info__search-date:after, .pax-info__input-field:after {
  clear: both;
}
.pax-info__search-time_select, .pax-info__search-date_select, .pax-info__input-field_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.pax-info__search-time_half, .pax-info__search-date_half, .pax-info__input-field_half {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}

.view__cart {
  float: right;
  padding: 0.3125rem;
}

.package__pax-info_main {
  margin-left: 0;
}

.sorter-header {
  margin-bottom: 0.9375rem;
}
.sorter-header__row {
  background-color: #fff;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.sorter-header__row:before, .sorter-header__row:after {
  content: " ";
  display: table;
}
.sorter-header__row:after {
  clear: both;
}
.sorter-header__row > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  color: #838383;
  text-align: center;
  border-right: 2px solid #b6b6b6;
  line-height: 0.75rem;
  text-transform: uppercase;
  cursor: pointer;
}
.sorter-header__row > li:last-child {
  border-right: 0px solid #b6b6b6;
}
.sorter-header .sorter-header__row_data {
  display: inline-block;
}
.sorter-header .triangle__container {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  position: relative;
  float: right;
  display: inline-block;
}
.sorter-header .triangle_down {
  height: 0;
  width: 0;
  border-color: white;
  border: 5px solid transparent;
  border-top: 5px solid #bfbfbf;
  position: absolute;
  margin-left: 0px;
  top: 10px;
  left: 4px;
  right: 0;
}
.sorter-header .triangle_up {
  height: 0;
  width: 0;
  border-color: white;
  border: 5px solid transparent;
  border-bottom: 5px solid #bfbfbf;
  position: absolute;
  bottom: 10px;
  left: 4px;
  right: 0;
}
.sorter-header .active_triangle_down {
  height: 0;
  width: 0;
  border-color: white;
  border: 5px solid transparent;
  border-bottom: 5px solid #087dc2;
  position: absolute;
  bottom: 10px;
  left: 4px;
  right: 0;
}
.sorter-header .active_triangle_up {
  height: 0;
  width: 0;
  border-color: white;
  border: 5px solid transparent;
  border-top: 5px solid #087dc2;
  position: absolute;
  top: 10px;
  left: 4px;
  right: 0;
}

.hub-surcharge__child-details, .hub-surcharge__adult-details {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.hub-surcharge__child-details_heading, .hub-surcharge__adult-details_heading {
  background-color: #eceeef;
  color: #087dc2;
  font-size: 1rem;
  padding: 0 0.25rem;
}

.hub-surcharge__child-details_container, .hub-surcharge__adult-details_container {
  width: 100%;
  font-size: 1rem;
}
.hub-surcharge__child-details_container:before, .hub-surcharge__adult-details_container:before, .hub-surcharge__child-details_container:after, .hub-surcharge__adult-details_container:after {
  content: " ";
  display: table;
}
.hub-surcharge__child-details_container:after, .hub-surcharge__adult-details_container:after {
  clear: both;
}

.hub-surcharge {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.hub-surcharge__total-amount-container {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  padding: 0 0.25rem;
}
.hub-surcharge__total-amount-container:before, .hub-surcharge__total-amount-container:after {
  content: " ";
  display: table;
}
.hub-surcharge__total-amount-container:after {
  clear: both;
}
.hub-surcharge__total-amount-container_heading {
  float: left;
  color: #087dc2;
}
.hub-surcharge__total-amount-container_total-amount {
  float: right;
}
.hub-surcharge__total-amount-container_total-amount .total-amount__currency {
  color: #ec2227;
  font-size: 0.75rem;
}
.hub-surcharge__total-amount-container_total-amount .total-amount__value {
  font-family: "Open Sans";
}

.pax-details-container__pax-count, .pax-details-container__multiply-sign, .pax-details-container__hub-surcharge-amount {
  float: left;
}
.pax-details-container__pax-count {
  width: 20%;
  padding: 0 0.25rem;
}
.pax-details-container__multiply-sign {
  width: 5%;
}
.pax-details-container__hub-surcharge-amount {
  width: 20%;
  font-family: "Open Sans";
  padding: 0 0.5rem;
}
.pax-details-container__pax-total-amount {
  float: right;
  padding-right: 0.25rem;
}
.pax-details-container__pax-total-amount_currency {
  color: #ec2227;
  font-size: 0.75rem;
}
.pax-details-container__pax-total-amount_amount {
  font-family: "Open Sans";
}