.package__search_result {

  .fp-search__result {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-footer;
    &_package,
    &_price {
      @include make-one-fifth;
      @include clearfix;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      padding-top: $base-padding/3;
    }

	&_row {
		position: relative;
	}
	
	&_tax-header{
	    @include clearfix;
		margin-top: -22px;
		margin-left: 19.1rem;
		margin-right: 15px;
		padding: 0.25rem;
	    color: $dark-green;
	    background-color: $saffron;
	    box-shadow: $row-shadow-color;
	    margin-bottom: 1rem;
	    	.tax-message{
	    		font-size: 0.9rem;
	    		padding-left: 0.25rem;
	    	}
    }
	
    &_detail {
      @include make-three-fifth;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: $brand-color-3;
      min-height: calc(#{$base-padding}/3 + 80px);
    }

    &_description,
    &_extra-desc {
      padding-top: $base-padding/3;
      @include clearfix;
      width: 100%;
    }
    &_description {
      // border-bottom: 2px solid $brand-color-4;
      padding-bottom: $base-padding/3;
    }
    &_footer{
    background-color:$brand-color-4;
    }
  }

  .description {
    &__package {
      @include make-three-fifth;
      padding-left: 0;
      &_name,
      &_duration {
        max-height: 100px;
        text-align: left;
        font-family: $font-family-number;
        color: $brand-color-2;
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
      }
      &_duration {
        color: $brand-color-5;
        font-size: 12px;
        font-weight: 600;
      }
    }
    &__rating {
      @include make-two-fifth;
      padding-right: 0px;
      max-height: 100px;
      .glyphicon {
        color: $star-rating;
      }
    }
  }

  .package__rate-category {
      @include make-two-fifth;
      padding-right: 0px;
      &_text {
        color: $brand-color-5;
        text-transform: capitalize;
      }
    }

  .result {

    &__extra_details {
      font-size: 0.625rem;
		
	  &_supplier{
	  	float:left;
    	width:30%;
    	color:$brand-color-2;
	  }
	  &_wrapper{
    	float:right;
    	width:70%;
    	.extra_details-content{
    		float:right;
    		&__package-details {
		        cursor: pointer;
        		padding: .3125rem 3.5rem;
        		background-color: $brand-color-3;
		        float: left;
		        > a{
			    	text-transform:uppercase;
		    	    color:$brand-color-2;
        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
    		text-transform: uppercase;
    		font-size: .625rem;
    		font-weight: 600;
         	color: $row-flight-details-button-state;
         	text-decoration: none;
	    		}
        &:hover {
          text-decoration: underline;
        }
      }
      		}
		    &__cancellation {
        		width: 70%;
		        padding-right: 0px;
		        padding-left: 0px;
		        cursor: pointer;
		        float: left;
		        > a{
	        		margin-right: 1rem;
			    	text-transform:uppercase;
            		width: 42%;
		    	    color:$brand-color-2;
	    		}
		    }
		    &__expand-package{
		    	float: left;
		    }
	  	}
	  }
    }



    &__extra_details {
      @include make-three-fifth;
      background-color: $row-footer-bgcolor;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: $brand-color-3;
      &_text {
        float: left;
      }
    }

    &__package {
      text-align: center;
      max-height: 100px;
      img {
        width: 100%;
        max-height: 70px;
        max-width: 120px;
      }
    }

    &__price {
    	float: left;
    	width: inherit;
      &_amount {
        font-size: $font-size-h3;
        font-family: $font-family-number;
        color: $brand-color-1;
      }
    }
    &__rfq-check {
    	padding: 0.325rem;
    	text-align: center;
    }
    &__status {
    	position: absolute;
		width: 18%;
		bottom: 0px;
    	text-align: center;
    }

    &__package_slideshow_button,
    &__extra_details_text,
    &__book_button,
    &__select_button,
    &__select_button-active{
      cursor: pointer;
      text-transform: uppercase;
      font-size: $font-size-h6;
      font-weight: 600;
    }

    &__package_slideshow {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;

      border-color: $brand-color-3;
      &_button {
        width: 100%;
        background-color: $brand-color-3;
        padding-top: $base-padding/6;
        padding-bottom: $base-padding/6;
        display: block;
        text-align: center;
        color: $brand-color-2;
        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
    		text-transform: uppercase;
    		font-size: .625rem;
    		font-weight: 600;
          color: $row-flight-details-button-state;
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__book,
    &__select {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      border-color: $row-book-border-color;
      &_button, &_button-active {
        // @include make-button;
        padding-top: $base-padding/6;
        padding-bottom: $base-padding/6;
        background-color: $row-book-button-bgcolor;
        border: none;
        line-height: inherit;
        border-radius: 0;
        background-image: none;
        width: 100%;
        color: $light;
        font-weight: 700;
        &:hover,
        &:focus,
        &:active {
          background-color: $row-book-button-state;
        }
      }


    }
    &__select {
      float:right;
      height:0px;
      &_button {
        background-color: $brand-color-2;
        &:hover,
        &:focus,
        &:active {
          background-color: $brand-color-2;
        }

        &-active {
          color: $brand-color-2;
          background-color: $brand-color-3;
          &:hover,
          &:focus,
          &:active {
            background-color: $brand-color-3;
          }
        }
      }
    }
  }

  .select-button__triangle {
    position: relative;
    margin-left: 5px;
    top: 10px;
    @include make-triangle(bottom, 7px, $light);

    &-active {
      position: relative;
      margin-left: 5px;
      top: 10px;
      @include make-triangle(bottom, 7px, $brand-color-2);
    }
  }
}
.package-cancellation-policy-loader{
@include make-loader;
}
.package-result-price-status {
	position: absolute;
  	bottom: 0px;
  	text-align: center;
	
	&__price-changed {
	    color: $price-changed-status;
	}
	&__available{
	    color: $available-status;
		margin-left: 4rem;
	}
	&__unavailable{
	    color: $unavailable-status;
	}
}
.result__extra_details_cancellation {
	.cancellation__text {
		float: right;
        margin-right: 10%;
        position: relative;
        padding: .3125rem 0;
    	width: 30%;
    	text-align: center;
    	background-color: $brand-color-3;
    	&:hover,
     	&:active,
      	&:focus {
            cursor: pointer;
    	   	text-transform: uppercase;
    	   	font-size: .625rem;
    	   	font-weight: 600;
           	color: $row-flight-details-button-state;
           	text-decoration: none;
      	}
      	&:hover {
      	   	background-color:$brand-color-3;
           	text-decoration: underline;
        }
	}
}
.result-header {
	@include clearfix;
	text-transform: uppercase;
	color: $brand-color-2;
	border-bottom: 1px solid darken($brand-color-4,20);
	padding: $base-padding/5 $base-padding/3 $base-padding/5 $base-padding/3;
    margin-bottom: $base-margin/5;
  	margin-right: -$base-margin/2;    	
 	
  	&__city {
  		float:left;
  	}
  	&__dates {
  		float:right;
  	}
 }